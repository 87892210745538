import {useEffect, useState} from 'react';
import {Event} from './event';

const events = new Map<string, Event>();

export function useSerializedState<T>(
  def: T,
  key: string
): [T, (v: T) => void] {
  let evt = events.get(key);
  if (!evt) {
    evt = new Event();
    events.set(key, evt);
  }

  let defaultValue = def;
  const existing = window.localStorage.getItem(key);
  if (existing) {
    try {
      defaultValue = JSON.parse(existing);
    } catch {
      // Ignore
    }
  }
  const [state, setState] = useState<T>(defaultValue);

  useEffect(() => {
    const update = () => {
      try {
        const val = window.localStorage.getItem(key);
        if (val) setState(JSON.parse(val));
      } catch {
        // Ignore
      }
    };
    evt?.bindfn(update);
    return () => evt?.unbind(update);
  }, []);

  return [
    state,
    (v: T) => {
      window.localStorage.setItem(key, JSON.stringify(v));
      evt?.emit();
    },
  ];
}
