import React, {FC} from 'react';
import styled from 'styled-components';
import {Select} from '@zappar/foundry-react-components';
import {SettingContainer, ShortTextInput, Title} from '../utils';
import CustomRadioButton from './customradiobutton';

const ThemedSelect = styled(Select)<{themeName?: string}>`
  ${props =>
    props.themeName === 'designer' &&
    `
border: 1px solid #B2C4D7;
border-radius: 8px;
box-sizing: border-box;
padding: 11px 16px 9px 20px;
height: 40px;
appearance: none;
background: no-repeat calc(100% - 15px) url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='16' fill='%23344B60' viewBox='0 0 16 16' width='16'><path d='M4.22754 6.4709L4.28294 6.51718L8.0001 10.2344L11.7173 6.51718C11.8735 6.36097 12.1267 6.36097 12.2829 6.51718C12.4218 6.65603 12.4372 6.87157 12.3292 7.02747L12.2829 7.08287L8.28294 11.0829C8.23087 11.1349 8.16802 11.1697 8.10157 11.187L8.03419 11.1986H7.96601C7.89796 11.1928 7.83111 11.1697 7.77265 11.1292L7.71725 11.0829L3.71725 7.08287C3.56105 6.92666 3.56105 6.67339 3.71725 6.51718C3.83875 6.39569 4.01896 6.36869 4.16655 6.43618L4.22754 6.4709Z' /></svg>");
  `}
`;

interface FontSettingsProps {
  themeName?: string;
  variants?: string[];
  subsets?: string[];
  sourceDetails?: {name?: string; url?: string; logo?: string};
  fontVariant?: string;
  fontSubsets?: {[id: string]: true};
  fontCharacters?: string;
  setFontVariant?: (variant: string) => void;
  setFontSubsets?: (subsets: {[id: string]: true}) => void;
  setFontCharacters?: (chars: string) => void;
}

const FontSettings: FC<FontSettingsProps> = ({
  themeName,
  variants,
  subsets,
  fontVariant,
  fontSubsets,
  fontCharacters,
  setFontVariant,
  setFontSubsets,
  setFontCharacters,
}) => (
  <>
    {variants && (
      <SettingContainer>
        <Title>Variant</Title>
        <ThemedSelect
          themeName={themeName}
          value={fontVariant}
          onChange={evt => setFontVariant?.(evt.target.value)}
        >
          {variants.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </ThemedSelect>
      </SettingContainer>
    )}
    {subsets && (
      <SettingContainer>
        <Title>Subsets</Title>
        {subsets.map((sub: string) => (
          <CustomRadioButton
            key={sub}
            title={sub}
            checked={!!fontSubsets[sub]}
            onChecked={() => {
              if (setFontSubsets) {
                if (fontSubsets[sub]) {
                  const newSubs = {...fontSubsets};
                  delete newSubs[sub];
                  setFontSubsets?.(newSubs);
                } else {
                  setFontSubsets?.({...fontSubsets, [sub]: true});
                }
              }
            }}
          />
        ))}
      </SettingContainer>
    )}
    <SettingContainer>
      <Title>Character Subset</Title>
      <ShortTextInput
        width="calc(100% - 32px)"
        defaultValue={fontCharacters}
        onInput={evt =>
          setFontCharacters?.((evt.target as HTMLInputElement).value)
        }
        darken={true}
      ></ShortTextInput>
    </SettingContainer>
  </>
);

export default FontSettings;
