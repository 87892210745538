import React from 'react';
import styled from 'styled-components';
import Categories from './categories';
import {Button, Select} from '@zappar/foundry-react-components';
import {Title} from '../utils';

export const iconSize = 13;

const AssetDetailsContainer = styled.div<{width: string; height: string}>`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};

  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const PanelContainer = styled.div<{width: string}>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(${props => props.width} - 2 * 40px);
  height: 100%;
  padding: 0 40px;
  overflow: auto;
`;

const NameContainer = styled.div`
  position: relative;
  padding-left: 72px;
  padding-top: 40px;
  padding-bottom: 24px;
  flex: 0;
`;

const NameIcon = styled.svg`
  position: absolute;
  left: 40px;
  top: 40px;
  width: ${iconSize}px;
  height: ${iconSize}px;
  cursor: pointer;
  padding: 2px;
  > path {
    fill: ${props => props.theme.colors.fontColor};
  }
`;

const DescContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
  opacity: 0.6;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const SourceContainer = styled.div<{firstChild?: boolean}>`
  margin-top: auto;
`;

const SourceImage = styled.img`
  width: auto;
  height: auto;
  max-width: 70%;
  max-height: 32px;
`;

const SectionContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
`;

const LicenseSection = styled.div`
  flex: 1%;
`;

const FadedText = styled.div`
  opacity: 0.6;
`;

const ResolutionContainer = styled.div<{disabled: boolean}>`
  width: 100%;
  margin-bottom: 12px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const ButtonContainer = styled.div<{themeName?: string}>`
  position: relative;
  flex: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 80px);
  padding: 0px 40px 40px 40px;
  background-color: ${props => props.theme.colors.panelBackground};
  > button {
    width: 100%;
    text-transform: uppercase;
    color: white;
    ${props =>
      props.themeName === 'designer'
        ? `background-color: ${props.theme.colors.buttonColor}; padding: 10px 0; border-radius: 36px;`
        : ''}
  }
  > button:hover {
    ${props =>
      props.themeName === 'designer'
        ? `background-color: ${props.theme.colors.buttonHoverColor};`
        : ''}
  }
`;

const TagContainer = styled.div`
  margin-bottom: 40px;
`;

const TagWrapper = styled.div`
  white-space: wrap;
`;

const Tag = styled.span`
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;

const ThemedSelect = styled(Select)<{themeName?: string}>`
  ${props =>
    props.themeName === 'designer' &&
    `
border: 1px solid #B2C4D7;
border-radius: 8px;
box-sizing: border-box;
padding: 11px 16px 9px 20px;
height: 40px;
appearance: none;
background: no-repeat calc(100% - 15px) url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='16' fill='%23344B60' viewBox='0 0 16 16' width='16'><path d='M4.22754 6.4709L4.28294 6.51718L8.0001 10.2344L11.7173 6.51718C11.8735 6.36097 12.1267 6.36097 12.2829 6.51718C12.4218 6.65603 12.4372 6.87157 12.3292 7.02747L12.2829 7.08287L8.28294 11.0829C8.23087 11.1349 8.16802 11.1697 8.10157 11.187L8.03419 11.1986H7.96601C7.89796 11.1928 7.83111 11.1697 7.77265 11.1292L7.71725 11.0829L3.71725 7.08287C3.56105 6.92666 3.56105 6.67339 3.71725 6.51718C3.83875 6.39569 4.01896 6.36869 4.16655 6.43618L4.22754 6.4709Z' /></svg>");
  `}
`;

interface AssetDetailsProps {
  themeName?: string;
  variant?: 'model' | 'font' | 'dependency';
  width?: string;
  height?: string;
  disabled?: boolean;
  name: string;
  description?: string;
  categories?: string[];
  authors?: string[];
  tags?: string[];
  license?: string;
  sourceDetails?: {name?: string; url?: string; logo?: string};
  buttonText: string;
  resolution?: string;
  resolutionOptions?: string[];
  customSettings?: React.ReactNode;
  setResolution?: (res: string) => void;
  onButtonClick: () => void;
  onClose?: () => void;
  onTagSearch?: (text: string) => void;
  onCategoryAdd?: (categories: {[id: string]: true}) => void;
  children?: React.ReactNode;
}

const AssetDetails: React.FC<AssetDetailsProps> = ({
  width = '100%',
  height = '100%',
  disabled,
  name,
  description,
  categories,
  authors,
  tags,
  license,
  sourceDetails,
  buttonText,
  resolution,
  resolutionOptions,
  setResolution,
  onButtonClick,
  onClose,
  onTagSearch,
  onCategoryAdd,
  children,
}) => (
  <AssetDetailsContainer width={width} height={height}>
    <NameContainer>
      <NameIcon
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClose}
      >
        <path
          d="M6.00002 11.6795L0.320557 6.00002L6.00002 0.320557L6.76602 1.09458L2.40222 5.45837H11.6795V6.54166H2.40222L6.76602 10.9055L6.00002 11.6795Z"
          fill="white"
        />
      </NameIcon>
      {name ? name?.toUpperCase() : null}
    </NameContainer>
    <PanelContainer width={width}>
      {description ? <DescContainer>{description}</DescContainer> : null}
      {categories ? (
        <Categories
          controls={false}
          selected={categories.reduce((acc: {[id: string]: true}, key) => {
            return {...acc, [key]: true};
          }, {})}
          onCategoryAdd={key => onCategoryAdd?.({[key]: true})}
        />
      ) : null}
      {children}
      {sourceDetails ? (
        <SourceContainer>
          <Title>Source</Title>
          {sourceDetails.logo ? (
            <a target="_blank" href={sourceDetails.url}>
              <SourceImage alt={sourceDetails.name} src={sourceDetails.logo} />
            </a>
          ) : null}
        </SourceContainer>
      ) : null}
      <SectionContainer>
        {!!authors?.length && (
          <LicenseSection>
            <Title>{`Author${authors.length > 1 ? 's' : ''}`}</Title>
            {authors.map((name: string) => (
              <FadedText key={name}>{name}</FadedText>
            ))}
          </LicenseSection>
        )}
        <LicenseSection>
          <Title>License</Title>
          <FadedText>{license}</FadedText>
        </LicenseSection>
      </SectionContainer>
      {tags && tags.length ? (
        <TagContainer>
          <Title>Tags</Title>
          {tags ? (
            <TagWrapper>
              {tags.map((tag, idx) => (
                <Tag key={tag} onClick={() => onTagSearch?.(tag)}>{`${tag}${
                  idx !== tags.length - 1 ? ', ' : ''
                }`}</Tag>
              ))}
            </TagWrapper>
          ) : null}
        </TagContainer>
      ) : null}
      {resolutionOptions && (
        <ResolutionContainer disabled={disabled}>
          <ThemedSelect
            title="Resolution"
            value={resolution}
            onChange={evt => setResolution?.(evt.target.value)}
          >
            {resolutionOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </ThemedSelect>
        </ResolutionContainer>
      )}
    </PanelContainer>
    <ButtonContainer>
      <Button variant="primary" disabled={disabled} onClick={onButtonClick}>
        {buttonText}
      </Button>
    </ButtonContainer>
  </AssetDetailsContainer>
);

export default AssetDetails;
