import React, {useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import {Source} from '../types';
import {avaturnOrigin, readyPlayerOrigin} from '../utils';

const GeneratorContainer = styled.div<{show: boolean}>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 118px);
  margin-top: 40px;
  opacity: ${props => (props.show ? 1 : 0)};
`;

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  border: none;
  background: transparent;
`;

interface AvatarGeneratorProps {
  source: Source;
  reset?: number; // increase this number to reload the generator iframe
  show?: boolean;
  onAvatarModel: (url: string) => void;
}

const AvatarGenerator: React.FC<AvatarGeneratorProps> = ({
  source,
  reset,
  show,
  onAvatarModel,
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    window.addEventListener('message', subscribe);
    document.addEventListener('message', subscribe);
    return () => {
      iframeRef.current = null;
      window.removeEventListener('message', subscribe);
      document.removeEventListener('message', subscribe);
    };
  }, []);

  const subscribe = event => {
    const json = parseEvent(event);

    const eventSource = event.origin ?? event.source;
    if (!(eventSource === readyPlayerOrigin || eventSource === avaturnOrigin)) {
      return;
    }

    switch (eventSource) {
      case avaturnOrigin:
        if (json?.data?.url) {
          onAvatarModel(json.data.url);
        }
        break;
      case readyPlayerOrigin:
        if (!json) {
          const linkArray = event.data.split('.');
          if (linkArray[linkArray.length - 1] === 'glb') {
            console.log(`Avatar Direct URL: ${event.data}`);
            onAvatarModel(linkArray[linkArray.length - 2]?.split('/')?.[1]);
          }
        }
        break;
    }
  };

  function parseEvent(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }

  const onIframeRef = (ref: HTMLIFrameElement | null) => {
    if (ref && !iframeRef.current) {
      iframeRef.current = ref;
      initGenerator();
    }
  };

  const initGenerator = () => {
    if (!iframeRef.current) return;
    switch (source) {
      case Source.ReadyPlayer:
        iframeRef.current.src = `${readyPlayerOrigin}/avatar?frameApi`;
        break;
      case Source.Avaturn:
        iframeRef.current.src = avaturnOrigin;
        break;
    }
  };

  const iframeMemo = useMemo(() => {
    iframeRef.current = null;
    switch (source) {
      case Source.ReadyPlayer:
        return (
          <IFrame
            key={Source.ReadyPlayer}
            id={Source.ReadyPlayer}
            allow="camera *; microphone *; clipboard-write"
            ref={onIframeRef}
          ></IFrame>
        );
      default:
      case Source.Avaturn:
        return (
          <IFrame
            key={Source.Avaturn}
            id={Source.Avaturn}
            allow="camera *; clipboard-write"
            allowFullScreen
            ref={onIframeRef}
          ></IFrame>
        );
    }
  }, [source, reset]);

  return <GeneratorContainer show={show}>{iframeMemo}</GeneratorContainer>;
};

export default AvatarGenerator;
