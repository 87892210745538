import React, {FC, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {boxShadow, buttonSize, buttonSizeSml} from '../utils';
import {CategoriesType} from '../types';

const dropdownWidth = 240;
const dropdownHeight = 'calc(80vh - 160px)';

const CategoriesContainer = styled.div<{
  controls: boolean;
  disabled: boolean;
}>`
  position: relative;
  width: calc(100% - 16px);
  flex: 0;
  height: fit-content;
  margin-top: ${props => (props.controls ? 40 : 0)}px;
  margin-bottom: ${props => (props.controls ? 16 : 40)}px;
  margin-left: ${props => (props.controls ? 8 : 0)}px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  opacity: ${props => (props.disabled ? 0.2 : 1)};
`;

const AddCategoryDropdown = styled.div<{hover: boolean; hoverHeight: number}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => (props.hover ? dropdownWidth : buttonSize)}px;
  height: ${props =>
    props.hover ? `${props.hoverHeight}px` : `${buttonSize}px`};
  border-radius: ${buttonSize / 2}px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  z-index: 1;
  box-shadow: ${boxShadow};
`;

const AddCategoryButton = styled.div<{hover: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${buttonSize}px;
  height: ${buttonSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${props => (props.hover ? 0 : 1)};
  pointer-events: ${props => (props.hover ? 'none' : 'auto')};
  transition: opacity 0.15s ease-out ${props => (props.hover ? 0 : 0.15)}s;
  background-color: ${props => props.theme.colors.menuBackgroundColor};
  user-select: none;
`;

const CategoryMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${dropdownWidth + 20}px;
  height: fit-content;
  max-height: ${dropdownHeight};
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: ${buttonSize / 2}px;
  padding-top: ${buttonSize}px;
  padding-bottom: 16px;
  background-color: ${props => props.theme.colors.menuBackgroundColor};
`;

const CategoryMenuItem = styled.div<{disabled: boolean}>`
  width: ${dropdownWidth}px;
  height: 40px;
  line-height: 40px;
  background-color: transparent;
  transition: background-color 0.2s ease-out;
  user-select: none;
  padding: 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
  cursor: pointer;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  :hover {
    background-color: ${props => props.theme.colors.panelBackground};
  }
`;

const AddCategoryIcon = styled.svg`
  width: 18px;
  height: 18px;
  > path {
    fill: ${props => props.theme.colors.fontColor};
  }
`;

const TagsContainer = styled.div<{hover: boolean; controls: boolean}>`
  display: flex;
  padding-left: ${props =>
    props.hover ? dropdownWidth + 20 : props.controls ? 56 : 0}px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: fit-content;
  min-height: 44px;
  transition: padding-left 0.3s ease-out;
`;

const Tag = styled.div<{passive: boolean; controls?: boolean}>`
  height: ${props => (props.controls ? buttonSize : buttonSizeSml)}px;
  line-height: ${props => (props.controls ? buttonSize : buttonSizeSml)}px;
  padding: 0 ${props => (props.controls ? 26 : 22)}px;
  background-color: ${props => props.theme.colors.hoverBackground};
  flex: 0;
  border-radius: ${props => (props.controls ? 18 : 12)}px;
  opacity: ${props => (props.passive ? 0.3 : 0.6)};
  transition: opacity 0.2s ease-out;
  margin-right: ${props => (props.controls ? 20 : 12)}px;
  margin-bottom: 8px;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
`;

interface OwnProps {
  controls?: boolean;
  categories?: CategoriesType;
  selected?: {[id: string]: true};
  themeName?: string;
  removeCategoryOnClick?: boolean;
  disabled?: boolean;
  onCategoryAdd?: (key: string, override?: boolean) => void;
  onCategoryRemove?: (key: string) => void;
}

const Categories: FC<OwnProps> = ({
  controls,
  categories,
  selected,
  removeCategoryOnClick,
  disabled,
  onCategoryAdd,
  onCategoryRemove,
}) => {
  const [hover, setHover] = useState(false);
  const [tagHover, setTagHover] = useState<string | null>(null);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const dropdownHeight = useRef<number>(0);

  useEffect(() => {
    setTagHover(null);
  }, [categories, selected]);

  const onHover = (state: boolean) => {
    if (menuRef.current) {
      dropdownHeight.current =
        menuRef.current.getBoundingClientRect()?.height ?? 0;
    }
    setHover(state);
  };

  const selectedList = disabled ? [] : Object.keys(selected ?? {});

  const isHover = hover && !disabled;

  return (
    <CategoriesContainer controls={controls} disabled={disabled}>
      <TagsContainer
        hover={isHover}
        controls={controls}
        onMouseLeave={() => setTagHover(null)}
      >
        {selectedList.map((key: string) => (
          <Tag
            controls={controls}
            key={key}
            passive={tagHover && tagHover !== key}
            onClick={() =>
              removeCategoryOnClick
                ? onCategoryRemove?.(key)
                : onCategoryAdd?.(key, true)
            }
            onMouseEnter={() => setTagHover(key)}
          >
            {key.toUpperCase()}
          </Tag>
        ))}
      </TagsContainer>
      {controls ? (
        <AddCategoryDropdown
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}
          hover={isHover}
          hoverHeight={dropdownHeight.current}
        >
          <CategoryMenu ref={menuRef}>
            {Object.keys(categories ?? {}).map((cat: string) => (
              <CategoryMenuItem
                key={cat}
                onClick={() => {
                  if (selected?.[cat]) {
                    onCategoryRemove?.(cat);
                  } else {
                    onCategoryAdd?.(cat);
                  }
                }}
                disabled={
                  selected?.[cat] ||
                  (Object.keys(selected ?? {}).length === 0 && cat === 'all')
                }
              >
                {cat.toUpperCase()}
              </CategoryMenuItem>
            ))}
          </CategoryMenu>
          <AddCategoryButton hover={isHover}>
            <AddCategoryIcon
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
            >
              <path d="M440-160q-17 0-28.5-11.5T400-200v-240L161-745q-14-17-4-36t31-19h584q21 0 31 19t-4 36L560-440v240q0 17-11.5 28.5T520-160h-80Zm40-276 240-304H240l240 304Zm0 0Z" />
            </AddCategoryIcon>
          </AddCategoryButton>
        </AddCategoryDropdown>
      ) : null}
    </CategoriesContainer>
  );
};

export default Categories;
