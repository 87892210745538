// Direct resource from Web Components repo

// Inspired by:
// - https://github.com/CesiumGS/gltf-pipeline/blob/0f963b95b402e4a4ddf1a6716c5e98c961e9e202/lib/gltfToGlb.js
// - https://github.com/BabylonJS/Babylon.js/blob/dd2eb63dd920f13cd7006fc89848d8fdd21bcdd7/packages/dev/serializers/src/glTF/2.0/glTFExporter.ts#L1191
// - https://github.com/visgl/loaders.gl/blob/7358e86fd7e89b5f3206af58e266fd2d332759ed/modules/gltf/src/lib/encoders/encode-glb.ts

import {GLTF} from './gltf2';

const MAGIC_glTF = 0x46546c67; // glTF in ASCII
const MAGIC_JSON = 0x4e4f534a; // JSON in ASCII
const MAGIC_BIN = 0x004e4942; // BIN\0 in ASCII

export function createGlb(gltf: GLTF, binaryBuffers: Uint8Array) {
  // https://www.khronos.org/registry/glTF/specs/2.0/glTF-2.0.html#glb-file-format-specification-general
  gltf.buffers = [{byteLength: binaryBuffers.byteLength}];

  const paddedJson = getGLTFPaddedBytes(gltf);

  // Compute glb length: (Global header) + (JSON chunk header) + (JSON chunk) + [(Binary chunk header) + (Binary chunk)]
  const totalByteLength =
    20 + paddedJson.byteLength + 8 + binaryBuffers.byteLength;

  const glb = new DataView(new ArrayBuffer(totalByteLength));

  // Global header (magic, version, length)
  let byteOffset = 0;
  glb.setUint32(byteOffset, MAGIC_glTF, true);
  byteOffset += 4;
  glb.setUint32(byteOffset, 2, true);
  byteOffset += 4;
  glb.setUint32(byteOffset, totalByteLength, true);
  byteOffset += 4;

  // JSON chunk (length, type, binary data)
  glb.setUint32(byteOffset, paddedJson.byteLength, true);
  byteOffset += 4;
  glb.setUint32(byteOffset, MAGIC_JSON, true);
  byteOffset += 4;
  new Uint8Array(glb.buffer).set(paddedJson, byteOffset);
  byteOffset += paddedJson.byteLength;

  if (binaryBuffers.byteLength > 0) {
    // Buffer chunk (length, type, binary data)
    glb.setUint32(byteOffset, binaryBuffers.byteLength, true);
    byteOffset += 4;
    glb.setUint32(byteOffset, MAGIC_BIN, true);
    byteOffset += 4;
    // Add externalResources to dataView at byteOffset
    new Uint8Array(glb.buffer).set(binaryBuffers, byteOffset);
  }

  return glb.buffer;
}

function getGLTFPaddedBytes(gltf: GLTF, byteOffset = 0) {
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const encoder = new TextEncoder();
  let string = JSON.stringify(gltf);

  const boundary = 4;
  const byteLength = encoder.encode(string).byteLength;
  const remainder = (byteOffset + byteLength) % boundary;
  const padding = remainder === 0 ? 0 : boundary - remainder;
  let whitespace = '';
  for (let i = 0; i < padding; ++i) {
    whitespace += ' ';
  }
  string += whitespace;

  return encoder.encode(string);
}
