export var TypeHint;
(function (TypeHint) {
    TypeHint["proportion"] = "proportion";
    TypeHint["color-norm-rgb"] = "color-norm-rgb";
    TypeHint["color-unnorm-rgb"] = "color-unnorm-rgb";
    TypeHint["color-hex"] = "color-hex";
})(TypeHint || (TypeHint = {}));
function valuesCompatible(l, r) {
    if (!l && !r)
        return true;
    if (!l || !r)
        return false;
    for (const [k, v] of Object.entries(l)) {
        if (r[k] !== v)
            return false;
    }
    return true;
}
export function areTypesCompatible(a, b) {
    if (a.name !== b.name)
        return false;
    switch (a.name) {
        case 'array':
            return areTypesCompatible(a.child, b.child);
        case 'literal':
            return a.value === b.value;
        case 'enum': {
            const bt = b;
            if (!valuesCompatible(a.values, bt.values))
                return false;
            if (!valuesCompatible(bt.values, a.values))
                return false;
            break;
        }
        case 'union': {
            const bt = b;
            for (let achild of a.children) {
                let foundCompatible = false;
                for (let bchild of bt.children) {
                    if (areTypesCompatible(achild, bchild)) {
                        foundCompatible = true;
                        break;
                    }
                }
                if (!foundCompatible)
                    return false;
            }
            return true;
        }
        case 'tuple': {
            const bt = b;
            if (a.children.length !== bt.children.length)
                return false;
            for (let i = 0; i < a.children.length; i++) {
                if (!areTypesCompatible(a.children[i], bt.children[i]))
                    return false;
            }
            return true;
        }
        case 'function': {
            const bt = b;
            if (a.args.length !== bt.args.length)
                return false;
            for (let i = 0; i < a.args.length; i++) {
                if (!areTypesCompatible(a.args[i].type, bt.args[i].type))
                    return false;
            }
            if (a.ret && !bt.ret)
                return false;
            if (!a.ret && !bt.ret)
                return false;
            if (a.ret && bt.ret)
                return areTypesCompatible(a.ret, bt.ret);
        }
    }
    return true;
}
export function mergeValues(a, b) {
    if (a === undefined && b === undefined)
        return undefined;
    a = a ?? [];
    b = b ?? [];
    const fileValues = new Set();
    const ret = [];
    for (const entry of a) {
        if (entry.type === 'files')
            fileValues.add(entry.param);
        ret.push(entry);
    }
    for (const entry of b) {
        if (entry.type === 'files' && fileValues.has(entry.param))
            continue;
        ret.push(entry);
    }
    return ret;
}
export function mergeProps(a, b) {
    if (a.name !== b.name)
        return;
    const type = mergeTypes(a.type, b.type);
    if (!type)
        return;
    let def;
    if (a.default !== undefined && b.default !== undefined && JSON.stringify(a.default) === JSON.stringify(b.default))
        def = a.default;
    return {
        name: a.name,
        comments: mergeComments(a.comments ?? [], b.comments ?? []),
        type,
        default: def,
        group: a.group === b.group ? a.group : undefined,
        groupPriority: Math.max(a.groupPriority ?? 0, b.groupPriority ?? 0),
        values: mergeValues(a.values, b.values)
    };
}
function mergeComments(a, b) {
    const s = new Set([...a, ...b]);
    return [...s.values()];
}
function mergeValueComments(a, b) {
    const ret = {};
    for (const [k, v] of Object.entries(a)) {
        ret[k] = mergeComments(a[k], b[k]);
    }
    return ret;
}
export function mergeTypes(a, b) {
    if (!areTypesCompatible(a, b))
        return;
    const typeHint = a.typeHint === b.typeHint ? a.typeHint : undefined;
    switch (a.name) {
        case 'string':
            return {
                name: a.name,
                typeHint,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
            };
        case 'number':
            return {
                name: a.name,
                typeHint,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
            };
        case 'enum':
            return {
                name: a.name,
                typeHint,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
                values: a.values,
                valueComments: a.valueComments ? mergeValueComments(a.valueComments, b.valueComments) : undefined,
            };
        case 'boolean':
            return {
                name: a.name,
                typeHint,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
            };
        case 'unknown':
            return {
                name: a.name,
                typeHint,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
            };
        case 'literal':
            return {
                name: a.name,
                typeHint,
                value: a.value,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
            };
        case 'array': {
            const child = mergeTypes(a.child, b.child);
            if (!child)
                return undefined;
            return {
                name: a.name,
                typeHint,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
                child,
            };
        }
        case 'tuple': {
            const children = [];
            for (let i = 0; i < a.children.length; i++) {
                const merged = mergeTypes(a.children[i], b.children[i]);
                if (!merged)
                    return undefined;
                children.push(merged);
            }
            return {
                name: a.name,
                typeHint,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
                children,
            };
        }
        case 'union':
            return {
                name: a.name,
                typeHint,
                children: a.children,
                comments: mergeComments(a.comments ?? [], b.comments ?? [])
            };
        case 'function':
            return {
                name: a.name,
                typeHint,
                args: a.args,
                ret: a.ret,
                comments: mergeComments(a.comments ?? [], b.comments ?? [])
            };
        case 'event':
            return {
                name: a.name,
                typeHint,
                comments: mergeComments(a.comments ?? [], b.comments ?? []),
            };
    }
}
const regex = new RegExp(/((\.tsx?)|(\.d\.ts)|(\.jsx?))$/, 'i');
export function symbolPathFromFilename(f) {
    return f.replace(regex, '');
}
export function isValidValueForType(def, t, allowUndefined) {
    if (allowUndefined && typeof def === 'undefined')
        return true;
    switch (t.name) {
        case 'boolean': return typeof def === 'boolean';
        case 'number': return typeof def === 'number';
        case 'string': return typeof def === 'string';
        case 'enum': {
            for (const v of Object.values(t.values)) {
                if (v === def)
                    return true;
            }
            return false;
        }
        case 'unknown':
            return true;
        case 'array': {
            if (!Array.isArray(def))
                return false;
            for (const entry of def) {
                if (!isValidValueForType(entry, t.child))
                    return false;
            }
            return true;
        }
        case 'tuple': {
            if (!Array.isArray(def))
                return false;
            if (def.length !== t.children.length)
                return false;
            for (let i = 0; i < t.children.length; i++) {
                if (!isValidValueForType(def[i], t.children[i]))
                    return false;
            }
            return true;
        }
        case 'union': {
            for (const child of t.children) {
                if (isValidValueForType(def, child))
                    return true;
            }
            return false;
        }
        case 'literal': return def === t.value;
        case 'function': return false;
    }
    return false;
}
export function outputForType(t, alwaysBasic = false) {
    if (t.isObservable && !alwaysBasic)
        return `Observable<${getBasicType(t)}>`;
    return getBasicType(t);
}
function getBasicType(t) {
    switch (t.name) {
        case 'array':
            return outputForType(t.child) + '[]';
        case 'tuple':
            return `[${t.children.map(c => outputForType(c)).join(', ')}]`;
        case 'unknown':
            return 'any';
        case 'number':
        case 'enum':
        case 'string':
        case 'boolean':
            return t.name;
        case 'literal': {
            if (t.value === undefined)
                return "undefined";
            return JSON.stringify(t.value);
        }
        case 'union': return t.children.map(val => outputForType(val)).join(' | ');
        case 'function': return `(${t.args.map(entry => `${entry.name}: ${outputForType(entry.type)}`).join(', ')}) => ${t.ret ? outputForType(t.ret) : 'void'}`;
        case 'event':
            return 'Event';
    }
}
