import {Button, Loader} from '@zappar/foundry-react-components';
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Source} from '../types';
import AssetCard from './assetcard';

const AssetContent = styled.div<{centered: boolean}>`
  position: relative;
  width: 100%;
  min-height: 270px;
  padding-bottom: 12px;
  ${props =>
    props.centered
      ? 'align-items: center; justify-content: center; display: flex;'
      : ''}
`;

const LoadMoreContainer = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const NoResults = styled.div`
  width: 100%;
  min-height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 32px;
  opacity: 0.3;
`;

interface AssetListProps {
  contentList: any[];
  source: Source;
  resetHover?: number;
  showMainLoader?: boolean;
  showLoadMore?: boolean;
  isContentListLoading?: boolean;
  templateText?: string;
  loadNextPage: () => void;
  onClick: (key: string) => void;
}

const AssetList: React.FC<AssetListProps> = ({
  contentList,
  source,
  resetHover,
  showMainLoader,
  showLoadMore,
  isContentListLoading,
  templateText,
  loadNextPage,
  onClick,
}) => {
  const [hover, setHover] = useState<null | string>(null);

  useEffect(() => {
    setHover(null);
  }, [resetHover]);

  const onHover = (key: string) => {
    if (hover !== key) {
      setHover(key);
    }
  };

  const onWheel = () => {
    if (hover) {
      setHover(null);
    }
  };

  const onContainerOut = () => {
    if (hover) {
      setHover(null);
    }
  };

  return (
    <AssetContent
      centered={showMainLoader}
      onMouseLeave={onContainerOut}
      onWheel={onWheel}
    >
      {showMainLoader ? (
        <Loader />
      ) : Object.keys(contentList).length ? (
        contentList.map(item => {
          const {id, name, thumb} = item;

          return (
            <AssetCard
              key={id}
              id={id}
              name={name ?? ''}
              passive={hover && hover !== id}
              onHover={onHover}
              onClick={onClick}
              source={source}
              img={thumb}
              fontFamily={name}
              templateText={templateText}
            />
          );
        })
      ) : (
        <NoResults>No results</NoResults>
      )}
      {showLoadMore && !showMainLoader && (
        <LoadMoreContainer>
          {isContentListLoading ? (
            <Loader />
          ) : (
            <Button variant="primary" onClick={loadNextPage}>
              Load more
            </Button>
          )}
        </LoadMoreContainer>
      )}
    </AssetContent>
  );
};

export default AssetList;
