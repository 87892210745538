import React, {FC} from 'react';
import styled from 'styled-components';

const RadioButton = styled.div<{passive?: boolean}>`
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  text-transform: none;
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 1px solid ${props => props.theme.colors.fontColor};
  box-sizing: border-box;
  user-select: none;
  margin-right: 10px;
  cursor: pointer;
`;

const CheckBoxInside = styled.div`
  width: 80%;
  height: 80%;
  background: ${props => props.theme.colors.fontColor};
`;

const CustomRadioButtonIcon: FC<{checked: boolean}> = ({checked}) => (
  <CheckBox>{checked ? <CheckBoxInside /> : null}</CheckBox>
);

interface CustomRadioButtonProps {
  title: string;
  checked: boolean;
  onChecked: () => void;
}

const CustomRadioButton: FC<CustomRadioButtonProps> = ({
  title,
  checked,
  onChecked,
}) => (
  <RadioButton onClick={onChecked}>
    <CustomRadioButtonIcon checked={checked} />
    {title}
  </RadioButton>
);

export default CustomRadioButton;
