// eslint-disable-next-line node/no-extraneous-import

export enum Source {
  PolyHaven = 'Poly Haven',
  Sketchfab = 'Sketchfab',
  PolyPizza = 'PolyPizza',
  GoogleFonts = 'Google Fonts',
  ReadyPlayer = 'Ready Player Me',
  Avaturn = 'Avaturn',
  AvatarIntro = 'Choose a provider...',
}

export enum Dropdown {
  Source = 'source',
}

export enum SourceType {
  Models = 'models',
  Textures = 'textures',
  Hdris = 'hdris',
  Fonts = 'fonts',
  Avatars = 'avatars',
}

export enum Theme {
  Dark = 'studio-dark',
  Light = 'studio-light',
  Designer = 'designer',
}

export type ModelResolution = '1k' | '2k' | '4k' | '8k' | '16k';

export interface DataItem {
  id: string;
  name: string;
  categories?: string[];
  tags?: string[];
  file?: string;
  variants?: string[];
  subsets?: string[];
  thumb?: string;
  cover?: string;
  description?: string;
  license?: string;
  authors?: string[];
}

export interface Data {
  [id: string]: DataItem;
}

export interface CategoriesType {
  [id: string]: string | number;
}

export interface AvatarSetupItem {
  key: string;
  name: string;
  defaultValue?: string;
  note?: string;
  options: {[key: string]: string};
  values?: {[key: string]: {[valueID: string]: string}};
  twoLines?: boolean;
  isMultiSelect?: boolean;
  isDetail?: boolean;
  isExclusion?: boolean;
}

export type AvatarSetup = AvatarSetupItem[];
