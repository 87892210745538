import {createGlb} from './gltf-to-glb';
import {GLTF} from './gltf2';
import {
  LoadingResourcesByUri,
  OutputErrorCode,
  ResouceData,
  ResourcesByUri,
} from './interfaces';
import * as mime from 'mime';

export const emitInvalidError = err => console.error('INVALID', err);
export const emitMissingFilesError = err => console.error('MISSING FILE', err);

export const _slugify = (s: string) => {
  if (s.endsWith('.zip')) s = s.substring(0, s.length - 4);
  else if (s.endsWith('.glb')) s = s.substring(0, s.length - 4);
  else if (s.endsWith('.gltf')) s = s.substring(0, s.length - 5);
  // https://gist.github.com/codeguy/6684588?permalink_comment_id=3332719#gistcomment-3332719
  return s
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '-')
    .replace(/\-\-+/g, '-');
};

export const alignLength = (value: number) => {
  // align to a multiple of 4
  const boundary = 4;
  if (value === 0) return value;
  const multiple = value % boundary;
  if (multiple === 0) return value;
  return value + (boundary - multiple);
};

export const dataUriToBinary = (dataUri: string) => {
  // data:image/svg+xml;base64,PD94bWw...
  dataUri = dataUri.replace(/\r?\n/g, ''); // strip newlines
  const matches = dataUri.match(/^data:(.+);(.+),(.+)/);
  if (!matches || matches.length !== 4)
    return {bytes: undefined, mimeType: undefined};
  const mimeType = matches[1];
  const encoding = matches[2] as BufferEncoding;
  const data = matches[3];

  if (encoding !== 'base64')
    throw `Unsupported encoding in data URI: ${encoding}`;
  const bytes = new Uint8Array(
    atob(data)
      .split('')
      .map(c => {
        return c.charCodeAt(0);
      })
  );

  // console.log('dataUriToBinary:', dataUri.substring(0, 50), mimeType)
  return {bytes, mimeType};
};

export const loadFile = async (uri: string) => {
  try {
    const response = await fetch(uri);
    const data = await response.blob();
    const file = new File([data], uri);
    const dataByteArray = new Uint8Array(await file.arrayBuffer());
    return dataByteArray;
  } catch (e) {
    return null;
  }
};

export const getResourcesOfGltf = async (gltf: GLTF) =>
  new Promise<ResourcesByUri>(resolve => {
    const resources: LoadingResourcesByUri = {};
    if (typeof gltf.buffers !== 'undefined') {
      for (const buffer of gltf.buffers) {
        resources[buffer.uri] = undefined;
      }
    }
    if (typeof gltf.images !== 'undefined') {
      for (const image of gltf.images) {
        resources[image.uri] = undefined;
      }
    }

    Object.keys(resources).forEach(key =>
      loadFile(key).then(result => {
        resources[key] = result;
        const loadReady = Object.values(resources).reduce(
          (acc: boolean, curr) => (curr === undefined ? false : acc),
          true
        );
        if (loadReady) {
          resolve(resources as ResourcesByUri);
        }
      })
    );
  });

export const resourcesToByteArray = (
  gltf: GLTF,
  resourcesByUri: ResourcesByUri
) => {
  const binaryBuffers: Uint8Array[] = [];
  const filesMissing: string[] = [];
  const filesFound: ResouceData[] = [];
  let bufferOffset = 0;

  if (typeof gltf.buffers !== 'undefined') {
    for (const buffer of gltf.buffers) {
      if (typeof buffer.uri === 'string') {
        // const bufferUri = buffer.uri;
        let resourceBytes: Uint8Array | undefined;
        if (buffer.uri.startsWith('data:')) {
          ({bytes: resourceBytes} = dataUriToBinary(buffer.uri));
        } else {
          resourceBytes = resourcesByUri[buffer.uri];
        }
        if (typeof resourceBytes === 'undefined') {
          filesMissing.push(buffer.uri);
          continue;
        } else {
          // _usedResources.push(buffer.uri);
          filesFound.push({uri: buffer.uri, bytes: resourceBytes});
        }
        buffer.byteLength = resourceBytes.byteLength;
        const alignedBytes = new Uint8Array(alignLength(buffer.byteLength));
        alignedBytes.set(resourceBytes, 0);
        binaryBuffers.push(alignedBytes);
        delete buffer.uri;
      } else {
        binaryBuffers.push(new Uint8Array(alignLength(buffer.byteLength)));
      }

      bufferOffset += alignLength(buffer.byteLength);
    }
  }

  if (typeof gltf.images !== 'undefined') {
    if (typeof gltf.bufferViews === 'undefined') {
      gltf.bufferViews = [];
    }
    for (const img of gltf.images) {
      const bufferView = {
        buffer: 0,
        byteOffset: bufferOffset,
        byteLength: img.byteLength || 0,
      };
      if (typeof img.uri === 'string') {
        let resourceBytes: Uint8Array | undefined, mimeType: string | undefined;
        if (img.uri.startsWith('data:')) {
          ({bytes: resourceBytes, mimeType} = dataUriToBinary(img.uri));
        } else {
          resourceBytes = resourcesByUri[img.uri];
        }
        if (typeof resourceBytes === 'undefined') {
          filesMissing.push(img.uri);
          continue;
        } else {
          // _usedResources.push(img.uri);
          filesFound.push({uri: img.uri, bytes: resourceBytes});
        }
        // console.log('mimeType:', mimeType, img.uri.substring(0, 50))
        if (typeof mimeType === 'undefined') {
          mimeType = mime.getType(img.uri) || 'application/octet-stream';
        }
        img.mimeType = mimeType || 'application/octet-stream';
        bufferView.byteLength = resourceBytes.byteLength;
        const alignedBytes = new Uint8Array(
          alignLength(resourceBytes.byteLength)
        );
        alignedBytes.set(resourceBytes, 0);
        binaryBuffers.push(alignedBytes);
      } else {
        binaryBuffers.push(new Uint8Array(img.byteLength));
      }

      img.bufferView = gltf.bufferViews.length; // Index
      gltf.bufferViews.push(bufferView);
      delete img.uri;
      bufferOffset += alignLength(bufferView.byteLength);
    }
  }

  if (filesMissing.length > 0) {
    throw {filesFound, filesMissing};
  }

  const res = new Uint8Array(bufferOffset);
  let offset = 0;
  for (const buf of binaryBuffers) {
    res.set(buf, offset);
    offset += buf.byteLength;
  }
  return res;
};

export const convertToGlb = async (filename: string, gltf: GLTF) => {
  const binaryBuffers = new Uint8Array(0);
  const originalGltfFileAsString = JSON.stringify(gltf);
  try {
    // !!!! binaryBuffers = resourcesToByteArray(gltf);
  } catch (err) {
    if (typeof (err as any).filesMissing === 'undefined') {
      emitInvalidError(err as any);
      return {errorCode: OutputErrorCode.INVALID_GLTF, error: err};
    } else {
      // Convert to File[] and include the glTF
      const filesFound: File[] = [
        new File([originalGltfFileAsString], `${_slugify(filename)}.gltf`, {
          type: 'model/gltf+json',
        }),
      ];
      for (const item of (err as any).filesFound) {
        filesFound.push(new File([item.bytes as Uint8Array], item.uri));
      }
      emitMissingFilesError((err as any).filesMissing);
      return {
        errorCode: OutputErrorCode.MISSING_FILES,
        filesFound,
        filesMissing: (err as any).filesMissing,
      };
    }
  }

  filename = `${_slugify(filename)}.glb`;
  return new Promise<File>(resolve => {
    const glb = createGlb(gltf, binaryBuffers);
    const glbFile = new File([glb], filename, {type: 'model/gltf-binary'});
    // emitGLB(glbFile);
    resolve(glbFile);
  });
};
