import {OrbitControls} from '@react-three/drei';
import {Canvas, Vector3} from '@react-three/fiber';
import {Loader} from '@zappar/foundry-react-components';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Box3} from 'three';
import {loadData, loadModelScene, middlewareURL} from '../utils';
import {Source} from '../types';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const CanvasCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.panelBoxBackground};
`;

const CanvasWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
  user-select: none;
`;

interface ModelProps extends OwnProps {
  setCameraPositions: (model: any) => void;
  onComplete: () => void;
}

const Model: React.FC<ModelProps> = ({
  modelUrl,
  modelData,
  scale,
  source,
  setCameraPositions,
  onComplete,
}) => {
  const [model, setModel] = useState(null);
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const textDecoder = React.useMemo(() => new TextDecoder(), []);

  useEffect(() => {
    if (modelUrl && source !== Source.Sketchfab) {
      loadModelScene(modelUrl, modelData).then(scene => {
        setModel(scene);
        setCameraPositions(scene);
        onComplete?.();
      });
    }
  }, [modelUrl, textDecoder]);

  return (
    <>{model && <primitive object={model} scale={[scale, scale, scale]} />}</>
  );
};

interface OwnProps {
  modelUrl: string;
  modelData: any;
  scale: number;
  source: Source;
}

const ModelPreview: React.FC<OwnProps> = ({
  modelUrl,
  modelData,
  source,
  scale = 1,
}) => {
  const [loaded, setLoaded] = useState(null);
  const [cameraPosition, setCameraPosition] = useState([0, 0, 0]);

  const setCameraPositions = (model: any) => {
    const boundingBox = new Box3();
    boundingBox.setFromObject(model, true);

    if (boundingBox) {
      const {max, min} = boundingBox;
      const maxSize = Math.max(max.x - min.x, max.y - min.y, max.z - min.z);

      setCameraPosition([maxSize / 2, min.y + (max.y - min.y) / 2, maxSize]);
    }
  };

  return (
    <Wrapper>
      <CanvasWrapper>
        <Canvas
          key={cameraPosition.toString()}
          resize={{offsetSize: true}}
          camera={{
            near: 0.001,
            position: cameraPosition as Vector3,
          }}
          shadows
        >
          <directionalLight position={[3.3, 1.0, 4.4]} castShadow />
          <ambientLight position={[-3.3, -1.0, -4.4]} intensity={0.5} />
          <Model
            modelUrl={modelUrl}
            modelData={modelData}
            onComplete={() => setLoaded(true)}
            setCameraPositions={setCameraPositions}
            scale={scale}
            source={source}
          />
          <OrbitControls target={[0, cameraPosition[1], 0]} />
          <axesHelper args={[5]} />
        </Canvas>
      </CanvasWrapper>
      {!loaded ? (
        <CanvasCover>
          <Loader />
        </CanvasCover>
      ) : null}
    </Wrapper>
  );
};

export default ModelPreview;
