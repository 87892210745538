/* eslint-disable no-case-declarations */
import {Select, Tab, TabBar} from '@zappar/foundry-react-components';
import React, {FC, useEffect, useRef, useState} from 'react';
import styled, {CSSProperties} from 'styled-components';
import Categories from './categories';

export const searchContainerHeightStart = 234;
export const searchContainerHeightEnd = 80;
const searchInputHeightStart = 48;
const searchInputHeightEnd = 38;
const searchInputTopStart = 42;
const searchInputTopEnd = 18;
const searchInputWidthStart = 520;
const searchInputWidthEnd = 320;

export const scrollSetup = {
  ['search-container']: {
    start: {
      minHeight: searchContainerHeightStart,
    },
    end: {
      minHeight: searchContainerHeightEnd,
    },
  },
  ['search-field']: {
    start: {
      height: searchInputHeightStart,
      marginLeft: 0,
    },
    end: {
      height: searchInputHeightEnd,
      marginLeft: -14,
    },
  },
  ['search-position']: {
    start: {
      left: 50,
      transform: -50,
      top: searchInputTopStart,
      width: searchInputWidthStart,
    },
    end: {
      left: 100,
      transform: -100,
      top: searchInputTopEnd,
      width: searchInputWidthEnd,
    },
  },
  ['search-settings']: {
    start: {
      opacity: 1,
    },
    end: {
      opacity: 0,
    },
  },
};

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.background};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TabContainer = styled.div<{themeName?: string}>`
  width: 100%;
  position: relative;
  margin-top: ${props => (props.themeName === 'designer' ? 22 : 0)}px;
  user-select: none;
`;

const StyledSelectInput = styled.div<{themeName?: string}>`
  display: flex;
  position: absolute;
  right: 12px;
  top: ${props => (props.themeName === 'designer' ? -22 : 5)}px;
  z-index: 1;
  user-select: none;
  & select {
    min-width: 40px;
    background: ${props => props.theme.colors.menuBackgroundColor};
  }
  & * {
    margin-left: 12px;
  }
`;

const SearchContainer = styled.div`
  min-height: 234px;
  position: relative;
  width: 100%;
  flex: 0;
`;

const SearchFieldPosition = styled.div`
  position: absolute;
  left: 50%;
  top: 42px;
  width: 520px;
  max-width: 80%;
  transform: translateX(-50%);
`;

const SearchField = styled.input`
  width: calc(100% - 60px);
  height: 48px;
  border-radius: 24px;
  line-height: 48px;
  padding: 0 30px;
  background-color: ${props => props.theme.colors.menuBackgroundColor};
  color: ${props => props.theme.colors.fontColor};
  border: none;
  outline: none;
  :active {
    outline: none;
  }
`;

const SearchSettingsContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 16px;
`;

const AssetContent = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 270px;
`;

const AssetScrollContainer = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 270px;
`;

const CategoryAbsoluteContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const ThemedSelect = styled(Select)<{themeName?: string}>`
  ${props =>
    props.themeName === 'designer' &&
    `
      border: 1px solid #B2C4D7;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 11px 16px 9px 20px;
      height: 40px;
      appearance: none;
      background: no-repeat calc(100% - 15px) url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='16' fill='%23344B60' viewBox='0 0 16 16' width='16'><path d='M4.22754 6.4709L4.28294 6.51718L8.0001 10.2344L11.7173 6.51718C11.8735 6.36097 12.1267 6.36097 12.2829 6.51718C12.4218 6.65603 12.4372 6.87157 12.3292 7.02747L12.2829 7.08287L8.28294 11.0829C8.23087 11.1349 8.16802 11.1697 8.10157 11.187L8.03419 11.1986H7.96601C7.89796 11.1928 7.83111 11.1697 7.77265 11.1292L7.71725 11.0829L3.71725 7.08287C3.56105 6.92666 3.56105 6.67339 3.71725 6.51718C3.83875 6.39569 4.01896 6.36869 4.16655 6.43618L4.22754 6.4709Z' /></svg>");
  `}
  width: 150px;
`;

export type OptionList = {
  name: string;
  id: string;
}[];

interface AssetFilterUIProps {
  themeName?: string;
  tabs?: OptionList;
  dropdowns?: {[id: string]: OptionList};
  categories?: OptionList;
  selectedTab?: string;
  selectedCategories?: {[id: string]: true};
  selectedDropdowns?: {[id: string]: string};
  searchText?: string;
  searchSettings?: React.ReactNode;
  reset?: number; // increase reset count to initiate UI reset
  resetSearch?: number; // increase resetSearch count to apply searchText in the search field
  allowMultiCategories?: boolean;
  noSearch?: boolean;
  onTab?: (id: string) => void;
  onCategory?: (categories: {[id: string]: true}, override?: boolean) => void;
  onDropdown?: (dropdownId: string, id: string) => void;
  onSearchText?: (txt: string, override?: boolean) => void;
  onWheel?: (evt: React.WheelEvent, allowScroll: boolean) => void;
  children?: React.ReactNode;
}

const AssetFilterUI: FC<AssetFilterUIProps> = ({
  themeName,
  tabs,
  dropdowns,
  categories,
  selectedTab,
  selectedCategories,
  selectedDropdowns,
  searchText,
  searchSettings,
  reset,
  resetSearch,
  allowMultiCategories,
  noSearch,
  onTab,
  onCategory,
  onDropdown,
  onSearchText,
  onWheel,
  children,
}) => {
  const [, setRender] = useState(0);

  const scrollElements = useRef<{[id: string]: HTMLDivElement}>({});
  const scrollValues = useRef<{[id: string]: CSSProperties}>({});
  const scrollTop = useRef(0);
  const scrollPosition = useRef(0);
  const isRealScroll = useRef(false);

  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  const resetSearchField = () => {
    if (searchFieldRef.current) {
      searchFieldRef.current.value = searchText ?? '';
    }
  };

  useEffect(() => {
    resetSearchField();
    scrollTop.current = 0;
    scrollPosition.current = 0;
    onWheelInternal({deltaY: 0});
    setRender(x => x + 1);
  }, [reset]);

  useEffect(resetSearchField, [resetSearch]);

  const onSearchKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === 'Enter' && searchFieldRef.current) {
      evt.stopPropagation();
      searchFieldRef.current.blur();
    }
    onSearchText?.(searchFieldRef.current.value);
  };

  const onSearchKeyUp = () => {
    onSearchText?.(searchFieldRef.current.value);
  };

  const onCategoryAdd = (key: string, override?: boolean) => {
    onCategory?.(
      key === 'all'
        ? {}
        : allowMultiCategories
        ? {...selectedCategories, [key]: true}
        : {[key]: true},
      override
    );
  };

  const onCategoryRemove = (key: string) => {
    const selected = {...selectedCategories};
    delete selected[key];
    onCategory?.(selected);
  };

  const addScrollElement = (ref: HTMLDivElement | null) => {
    if (
      ref &&
      ref.id &&
      !Object.keys(scrollElements.current).includes(ref.id)
    ) {
      scrollElements.current[ref.id] = ref;
    }
  };

  const onWheelInternal = evt => {
    const staticScrollDistance =
      searchContainerHeightStart - searchContainerHeightEnd;

    scrollTop.current += evt.deltaY;
    if (scrollTop.current < 0) {
      scrollTop.current = 0;
    }
    if (scrollTop.current > staticScrollDistance) {
      scrollTop.current = staticScrollDistance;
    }
    const percent = scrollTop.current / staticScrollDistance;
    const newCss = {};
    Object.keys(scrollElements.current).forEach(key => {
      const setupStart = scrollSetup[key]?.start;
      const setupEnd = scrollSetup[key]?.end;
      const element = scrollElements.current[key];

      if (setupStart !== undefined && setupEnd !== undefined && element) {
        const css = {};
        Object.keys(setupStart).forEach(cssProp => {
          const valueStart = setupStart[cssProp];
          const valueEnd = setupEnd[cssProp];
          const currentValue = valueStart + (valueEnd - valueStart) * percent;
          switch (cssProp) {
            case 'width':
            case 'height':
            case 'top':
            case 'minHeight':
            case 'marginLeft':
              css[cssProp] = `${currentValue}px`;
              break;
            case 'left':
              css[cssProp] = `${currentValue}%`;
              break;
            case 'transform':
              css[cssProp] = `translateX(${currentValue}%)`;
              break;
            default:
              css[cssProp] = currentValue;
          }
        });
        newCss[key] = css;
      }
    });
    if (JSON.stringify(newCss) !== JSON.stringify(scrollValues.current)) {
      scrollValues.current = newCss;
      isRealScroll.current =
        !scrollTop.current || scrollTop.current === staticScrollDistance;
      setRender(x => x + 1);
    }
    onWheel?.(evt, isRealScroll.current);
  };

  const onScroll = (evt?) => {
    if (evt) {
      if (!isRealScroll.current) {
        evt.target.scrollTop = scrollPosition.current;
      } else {
        scrollPosition.current = evt.target.scrollTop;
      }
    }
  };

  return (
    <MainContainer>
      <StyledSelectInput themeName={themeName}>
        {Object.keys(dropdowns ?? {}).map((key: string) => {
          const dropdown: OptionList = dropdowns[key]!;
          const options: string[] = dropdown.map(option => option.id);
          return (
            <ThemedSelect
              themeName={themeName}
              key={key}
              value={selectedDropdowns?.[key]}
              onChange={evt => onDropdown?.(key, evt.target.value)}
            >
              {options.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </ThemedSelect>
          );
        })}
      </StyledSelectInput>
      <TabContainer themeName={themeName}>
        <TabBar>
          {(tabs ?? []).map(tab => {
            return (
              <Tab
                key={tab.id}
                title={tab.name}
                selected={selectedTab === tab.id}
                onClick={() => onTab?.(tab.id)}
              />
            );
          })}
        </TabBar>
      </TabContainer>
      {!noSearch && (
        <SearchContainer
          id="search-container"
          ref={addScrollElement}
          style={scrollValues.current['search-container']}
        >
          <SearchFieldPosition
            id="search-position"
            ref={addScrollElement}
            style={scrollValues.current['search-position']}
          >
            <SearchField
              id="search-field"
              placeholder="Search..."
              ref={ref => {
                addScrollElement(ref);
                searchFieldRef.current = ref;
              }}
              style={scrollValues.current['search-field']}
              onKeyDown={onSearchKeyDown}
              onKeyUp={onSearchKeyUp}
            />
            <SearchSettingsContainer
              id="search-settings"
              ref={addScrollElement}
              style={scrollValues.current['search-settings']}
            >
              {searchSettings ?? null}
            </SearchSettingsContainer>
          </SearchFieldPosition>
          <CategoryAbsoluteContainer>
            <Categories
              controls={true}
              categories={(categories ?? []).reduce(
                (acc, cat) => ({...acc, [cat.id]: cat.name}),
                {}
              )}
              selected={selectedCategories}
              onCategoryAdd={onCategoryAdd}
              onCategoryRemove={onCategoryRemove}
              removeCategoryOnClick={true}
            />
          </CategoryAbsoluteContainer>
        </SearchContainer>
      )}

      <AssetContent>
        <AssetScrollContainer onWheel={onWheelInternal} onScroll={onScroll}>
          {children}
        </AssetScrollContainer>
      </AssetContent>
    </MainContainer>
  );
};

export default AssetFilterUI;
