import React, {FC} from 'react';
import styled from 'styled-components';
import {Source, Theme} from '../types';

const AvatarIntroContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 46px);
  max-height: 510px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarIntroCard = styled.div<{themeName: string; margin?: string}>`
  position: relative;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.panelBackground};
  border-radius: 6px;
  margin: ${props => props.margin ?? '0px'};
  cursor: pointer;
  svg {
    width: 162px;
    height: auto;
  }
  path {
    fill: ${props => (props.themeName === Theme.Dark ? '#FFFFFF' : '#222222')};
  }
`;

interface OwnProps {
  themeName: string;
  onSelect: (selected: string) => void;
}

const AvatarIntro: FC<OwnProps> = ({themeName, onSelect}) => {
  return (
    <AvatarIntroContainer>
      <AvatarIntroCard
        themeName={themeName}
        onClick={() => onSelect(Source.ReadyPlayer)}
      >
        <svg
          width="81"
          height="48"
          viewBox="0 0 81 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M41.1433 3.15429L41.4176 5.02857H45.2119L44.1147 0H39.0405L35.9319 5.02857H40.0919L41.1433 3.15429Z"
              fill="#F6F6F6"
            />
            <path
              d="M45.2565 13.1201L43.8394 6.40015H40.0451L40.3194 8.27443H37.6222L38.7194 6.40015H34.5594L30.4451 13.1201H34.9251L35.8851 11.383H40.8222L41.0965 13.1201H45.2565Z"
              fill="#F6F6F6"
            />
            <path
              d="M57.0508 12.2973C58.3765 11.7487 59.3822 10.9259 60.0679 9.92015C60.7536 8.91443 61.1194 7.77158 61.1194 6.40015H56.7765C56.7765 6.53729 56.8222 6.62872 56.8222 6.76586C56.8222 7.72586 56.5022 8.45729 55.8165 9.00586C55.1308 9.55443 54.2622 9.82872 53.1651 9.82872H51.5194L52.2051 6.40015H47.9994L46.6279 13.1201H52.3879C54.1708 13.1201 55.7251 12.8459 57.0508 12.2973Z"
              fill="#F6F6F6"
            />
            <path
              d="M53.9435 3.29149H55.6349C56.5035 3.29149 57.1435 3.47435 57.5549 3.79435C57.8749 4.06863 58.1035 4.48006 58.1492 5.02863H62.4921C62.4921 5.02863 62.4921 5.02863 62.4921 4.98292C62.4921 3.3372 61.9435 2.10292 60.8921 1.23435C59.8406 0.411489 58.2864 -0.0456543 56.1835 -0.0456543H50.4235L49.3721 5.02863H53.5321L53.9435 3.29149Z"
              fill="#F6F6F6"
            />
            <path
              d="M68.7996 8.91443L70.9024 6.40015H63.5881L64.5938 8.73158L63.6796 13.1201H67.931L68.7996 8.91443Z"
              fill="#F6F6F6"
            />
            <path
              d="M72.2746 5.02857L76.5717 0H72.0003L68.6631 4.16L67.0631 0H62.6746L64.9603 5.02857H72.2746Z"
              fill="#F6F6F6"
            />
            <path
              d="M11.8857 2.92571H13.7143C14.3086 2.92571 14.7657 3.01714 14.9943 3.15429C15.2686 3.29143 15.36 3.56571 15.36 3.93143C15.36 4.48 15.0857 4.84571 14.5371 5.02857H19.1543C19.2914 4.61714 19.3829 4.16 19.3829 3.65714C19.3829 2.51429 18.9714 1.6 18.1029 0.96C17.2343 0.32 16.0914 0 14.6286 0H8.41142L7.81714 2.92571H11.8857Z"
              fill="#F6F6F6"
            />
            <path
              d="M32.9603 0H22.3546L21.7146 2.97143H32.366L32.9603 0Z"
              fill="#F6F6F6"
            />
            <path
              d="M27.0176 5.4857L27.1548 4.8457H21.4405L21.3033 5.4857L20.8462 7.77142H26.5605L27.0176 5.4857Z"
              fill="#F6F6F6"
            />
            <path
              d="M29.9424 10.1487H19.1539L18.5596 13.1201H29.3481L29.9424 10.1487Z"
              fill="#F6F6F6"
            />
            <path
              d="M24.1827 26.7429H18.2399L18.9256 23.4058H14.6742L13.3027 30.1258H23.497L24.1827 26.7429Z"
              fill="#F6F6F6"
            />
            <path
              d="M16.0454 22.0344H20.2968L21.3026 17.0059H17.0968L16.0454 22.0344Z"
              fill="#F6F6F6"
            />
            <path
              d="M30.3084 28.3886H35.1998L35.5198 30.1258H39.6798L38.217 23.4058H34.4684L34.7427 25.28H32.0456L33.097 23.4058H28.9827L24.8684 30.1258H29.3484L30.3084 28.3886Z"
              fill="#F6F6F6"
            />
            <path
              d="M33.4626 17.0059L30.354 22.0344H34.4683L35.5197 20.1601L35.8397 22.0344H39.5883L38.5369 17.0059H33.4626Z"
              fill="#F6F6F6"
            />
            <path
              d="M42.9262 25.7372L42.0119 30.1258H46.2633L47.1319 25.92L49.2348 23.4058H41.8748L42.9262 25.7372Z"
              fill="#F6F6F6"
            />
            <path
              d="M50.3321 17.0059L46.9949 21.1659L45.3949 17.0059H41.0063L43.2463 22.0344H50.6063L54.9035 17.0059H50.3321Z"
              fill="#F6F6F6"
            />
            <path
              d="M8.82348 19.7486C9.41777 19.7486 9.87491 19.8401 10.1035 19.9772C10.3778 20.1144 10.4692 20.3429 10.4692 20.7086C10.4692 21.5772 9.82919 21.9886 8.50348 22.0344H14.2635C14.4921 21.5315 14.5835 20.9372 14.5835 20.2972C14.5835 19.1086 14.1721 18.1944 13.3949 17.6001C12.5721 17.0058 11.3835 16.6858 9.78348 16.6858H3.88634L3.24634 19.7486H7.31491H8.82348Z"
              fill="#F6F6F6"
            />
            <path
              d="M12.9371 23.4058H7.17714C7.13143 23.4058 7.04 23.4058 6.99429 23.4058H5.98857H5.53143H1.41714L0 30.1258H4.06857L4.84571 26.5143H6.62857C8.68571 26.5143 10.2857 26.1029 11.4743 25.2343C12.16 24.7315 12.6171 24.1372 12.9371 23.4058Z"
              fill="#F6F6F6"
            />
            <path
              d="M9.32509 9.27996H11.3365L12.8908 13.1657H17.3708L15.2679 8.63996C16.1365 8.27425 16.8222 7.77139 17.2794 7.13139C17.4622 6.90282 17.5994 6.62853 17.6908 6.35425H12.3422C12.2051 6.35425 12.0222 6.35425 11.8851 6.35425H10.5137H9.91937H5.94223L4.5708 13.12H8.50223L9.32509 9.27996Z"
              fill="#F6F6F6"
            />
            <path
              d="M67.0167 17.0515H56.3653L55.771 20.0229H66.3767L67.0167 17.0515Z"
              fill="#F6F6F6"
            />
            <path
              d="M54.8574 25.0058H60.5717L61.1203 22.0801H55.406L54.8574 25.0058Z"
              fill="#F6F6F6"
            />
            <path
              d="M52.6172 30.1714H63.4058L64 27.2H53.2115L52.6172 30.1714Z"
              fill="#F6F6F6"
            />
            <path
              d="M79.3604 17.9659C78.4919 17.3259 77.349 17.0059 75.8861 17.0059H69.669L69.0747 19.9316H73.1433H74.9718C75.5661 19.9316 76.0233 20.023 76.2519 20.1601C76.5261 20.2973 76.6176 20.5716 76.6176 20.9373C76.6176 21.4859 76.3433 21.8516 75.7947 22.0344H80.4118C80.549 21.623 80.6404 21.1659 80.6404 20.663C80.6404 19.5201 80.1833 18.6059 79.3604 17.9659Z"
              fill="#F6F6F6"
            />
            <path
              d="M78.5372 24.0916C78.72 23.863 78.8572 23.5887 78.9486 23.3145H73.6C73.4629 23.3145 73.28 23.3145 73.1429 23.3145H71.7715H71.1772H67.2L65.8286 30.1259H69.76L70.5829 26.2402H72.5943L74.1486 30.1259H78.6286L76.5258 25.6002C77.3943 25.2345 78.08 24.7316 78.5372 24.0916Z"
              fill="#F6F6F6"
            />
            <path
              d="M29.0281 37.8973H39.6338L40.2738 34.8801H29.6224L29.0281 37.8973Z"
              fill="#F6F6F6"
            />
            <path
              d="M34.3772 39.9087H28.6629L28.0686 42.8344H33.7829L34.3772 39.9087Z"
              fill="#F6F6F6"
            />
            <path
              d="M25.8743 48H36.6171L37.2571 45.0286H26.4686L25.8743 48Z"
              fill="#F6F6F6"
            />
            <path
              d="M11.291 39.9087H18.1024L16.9139 34.8801H12.3424L11.291 39.9087Z"
              fill="#F6F6F6"
            />
            <path
              d="M27.8854 34.8801H23.0397L19.7939 39.9087H26.8797L27.8854 34.8801Z"
              fill="#F6F6F6"
            />
            <path
              d="M17.1881 43.2457L16.731 41.28H9.91952L8.5481 48H12.3424L13.531 42.3315L14.7652 48H17.8281L21.4395 42.1943L20.251 48H24.1367L25.5081 41.28H18.4224L17.1881 43.2457Z"
              fill="#F6F6F6"
            />
          </g>
        </svg>
      </AvatarIntroCard>
      <AvatarIntroCard
        themeName={themeName}
        onClick={() => onSelect(Source.Avaturn)}
        margin="0 0 0 48px"
      >
        <svg
          width="88"
          height="15"
          viewBox="0 0 88 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.24019 14.9995C3.43288 14.9995 4.09664 14.4067 4.51149 12.9499L4.77077 12.0357H9.64526L9.90454 13.0203C10.2779 14.4268 10.952 14.9995 12.2484 14.9995C13.5241 14.9995 14.4471 14.1355 14.4471 12.9299C14.4471 12.4677 14.3642 12.0658 14.1567 11.5132L10.7757 2.55142C10.1016 0.763075 9.02298 -0.000488281 7.14579 -0.000488281C5.39305 -0.000488281 4.30407 0.793216 3.66105 2.55142L0.311137 11.5132C0.114084 12.0256 0 12.5682 0 12.9399C0 14.2259 0.840071 14.9995 2.24019 14.9995ZM5.60047 8.86086L7.08356 3.5963H7.16653L8.72222 8.86086H5.60047Z"
            fill="#fff"
          />
          <path
            d="M20.2239 14.9995C21.8418 14.9995 22.7234 14.3465 23.2523 12.8796L25.6066 6.39938C25.7311 6.04774 25.8555 5.59563 25.8555 5.21385C25.8555 4.20916 24.9428 3.40541 23.802 3.40541C22.7752 3.40541 22.0389 3.92784 21.7796 5.033L20.255 11.4228H20.1721L18.6164 5.09328C18.3364 3.94794 17.6311 3.40541 16.5629 3.40541C15.3806 3.40541 14.4264 4.12878 14.4264 5.23394C14.4264 5.68605 14.5405 6.14821 14.6753 6.51994L17.0296 12.8796C17.5689 14.3465 18.4816 14.9995 20.2239 14.9995Z"
            fill="#fff"
          />
          <path
            d="M30.1181 14.9091C31.4456 14.9091 32.8354 14.3465 33.4369 13.2614H33.5199C33.6547 14.467 34.4429 14.9995 35.563 14.9995C36.7868 14.9995 37.6684 14.3565 37.6684 12.9299V7.16294C37.6684 4.91244 35.5423 3.37527 32.3686 3.37527C29.5166 3.37527 27.5357 4.2192 27.1001 5.63581C27.0378 5.83675 27.0171 5.9975 27.0171 6.24867C27.0171 7.10266 27.7327 7.70547 28.7387 7.70547C29.3714 7.70547 29.9107 7.48444 30.4085 7.04238C31.1448 6.38933 31.4767 6.21853 32.1405 6.21853C32.9391 6.21853 33.5199 6.62041 33.5199 7.36388V7.90641L31.01 8.05711C27.9505 8.23796 26.26 9.39335 26.26 11.5233C26.26 13.5226 28.0335 14.9091 30.1181 14.9091ZM31.7049 12.1562C30.9582 12.1562 30.45 11.8046 30.45 11.2018C30.45 10.6593 30.9063 10.2875 31.7153 10.2272L33.5199 10.1067V10.8301C33.5199 11.6439 32.6487 12.1562 31.7049 12.1562Z"
            fill="#fff"
          />
          <path
            d="M40.3027 11.6539C40.3027 13.7939 41.485 14.9091 43.7874 14.9091H43.8807C45.426 14.9091 46.8573 14.3063 46.8573 13.1207C46.8573 12.1663 46.318 11.7845 45.426 11.674L45.1771 11.6439C44.7727 11.5936 44.5549 11.3726 44.5549 10.7396V6.65055H45.3016C46.2143 6.65055 46.8262 6.04774 46.8262 5.15356C46.8262 4.25939 46.2143 3.65658 45.3016 3.65658H44.5549V3.06381C44.5549 1.71753 43.7874 0.933872 42.4288 0.933872C41.0701 0.933872 40.3027 1.71753 40.3027 3.06381V3.65658H40.0434C39.1307 3.65658 38.5188 4.24934 38.5188 5.15356C38.5188 6.05778 39.1307 6.65055 40.0434 6.65055H40.3027V11.6539Z"
            fill="#fff"
          />
          <path
            d="M52.1777 14.9694C53.889 14.9694 54.9572 14.0752 55.4136 12.5682H55.4965V12.9098C55.4965 14.3465 56.347 14.9995 57.5708 14.9995C58.7946 14.9995 59.645 14.3465 59.645 12.8997V5.54539C59.645 4.07855 58.7635 3.40541 57.5189 3.40541C56.2744 3.40541 55.3928 4.07855 55.3928 5.53535V9.85551C55.3928 10.9305 54.7705 11.6439 53.8164 11.6439C52.8207 11.6439 52.1674 11.0209 52.1674 9.84546V5.54539C52.1674 4.07855 51.2858 3.40541 50.0412 3.40541C48.7967 3.40541 47.9151 4.07855 47.9151 5.53535V10.7698C47.9151 13.4121 49.7197 14.9694 52.1777 14.9694Z"
            fill="#fff"
          />
          <path
            d="M63.3683 14.9995C64.6751 14.9995 65.4944 14.2761 65.4944 12.8696V9.04171C65.4944 7.82604 65.7641 7.46435 66.9049 7.42416C67.6827 7.39402 68.2739 7.21318 68.668 6.87158C69.0621 6.52999 69.2592 6.03769 69.2592 5.38464C69.2592 4.62108 69.0414 4.08859 68.6161 3.78719C68.3569 3.5963 68.0042 3.49583 67.579 3.49583C66.4382 3.49583 65.6811 4.27948 65.4114 5.87694H65.3285V5.47506C65.3285 4.12878 64.5091 3.40541 63.3164 3.40541C62.0719 3.40541 61.2422 4.12878 61.2422 5.46502V12.8696C61.2422 14.2761 62.0615 14.9995 63.3683 14.9995Z"
            fill="#fff"
          />
          <path
            d="M72.2876 14.9995C73.5321 14.9995 74.4137 14.3264 74.4137 12.8696V8.54941C74.4137 7.51458 75.0256 6.76107 76.0316 6.76107C77.0998 6.76107 77.6391 7.44425 77.6391 8.55946V12.8696C77.6391 14.3264 78.5207 14.9995 79.7652 14.9995C81.0098 14.9995 81.8913 14.3264 81.8913 12.8696V7.64519C81.8913 4.98277 80.4808 3.43555 77.9088 3.43555C76.1664 3.43555 75.0774 4.17902 74.4966 5.73628H74.4137V5.48511C74.4137 4.15892 73.5529 3.40541 72.2253 3.40541C70.8978 3.40541 70.1615 4.15892 70.1615 5.47506V12.8696C70.1615 14.3264 71.043 14.9995 72.2876 14.9995Z"
            fill="#fff"
          />
          <path
            d="M85.8428 14.889C87.0355 14.889 88 13.9546 88 12.7992C88 11.6439 87.0355 10.7095 85.8428 10.7095C84.6501 10.7095 83.6856 11.6439 83.6856 12.7992C83.6856 13.9546 84.6501 14.889 85.8428 14.889Z"
            fill="#fff"
          />
        </svg>
      </AvatarIntroCard>
    </AvatarIntroContainer>
  );
};

export default AvatarIntro;
