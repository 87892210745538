import {Loader, RadioSwitcher} from '@zappar/foundry-react-components';
import React, {FC, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import WebFont from 'webfontloader';

import {
  AvatarSetup,
  AvatarSetupItem,
  DataItem,
  ModelResolution,
  Source,
  SourceType,
  Theme,
} from '../types';
import {
  buttonSize,
  buttonSizeSml,
  getHashString,
  getReadyPlayerLink,
  loadData,
  sourceAssets,
  sourceByType,
} from '../utils';

import Sketchfab from '@sketchfab/viewer-api';
import FontPreview from './fontpreview';
import ModelPreview from './modelpreview';

import iconContrast from '../assets/contrast.svg';
import icon3D from '../assets/icon3D.svg';
import iconImage from '../assets/iconImage.svg';
import Popup from './popup';
import AssetDetails from '../shared/AssetDetails';
import FontSettings from './fontsettings';
import AvatarSettings, {avatarSetup} from './avatarsettings';
import {useSerializedState} from '../store/useSerializedState';

const LeftPanel = styled.div`
  position: relative;
  width: 368px;
  height: 100%;
`;

const RightPanel = styled.div<{contrast: boolean}>`
  position: relative;
  height: 100%;
  flex: 1;
  background-color: ${props =>
    props.contrast ? '#FFFFFF' : props.theme.colors.panelBoxBackground};
  color: ${props => (props.contrast ? '#000000' : 'inherit')};
  & .zee-loading {
    background: none;
  }
`;

const Image = styled.img<{cover?: boolean; scroll?: boolean}>`
  ${props =>
    props.scroll
      ? `position: relative;
      width: auto; 
      height: 100%`
      : props.cover
      ? `width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;`
      : `width: calc(100% - 64px);
        height: calc(100% - 64px);
        object-fit: contain;
        position: absolute;
        margin-left: 32px;
        margin-top: 32px;`}
`;

const BottomNav = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

const IconContainer = styled.div`
  position: relative;
  width: ${buttonSize + 14}px;
  height: ${buttonSize + 14}px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => props.theme.colors.panelBackground};
  :hover > div {
    opacity: 1;
  }
`;

const IconButtonImage = styled.div<{src: string}>`
  width: ${buttonSizeSml}px;
  height: ${buttonSizeSml}px;
  mask-image: url(${props => props.src});
  -webkit-mask-image: url(${props => props.src});
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  background-color: ${props => props.theme.colors.fontColor};
  opacity: 0.6;
  transition: opacity 0.2 ease-out;
`;

const FullImageView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const SketchfabViewer = styled.iframe`
  border: none;
`;

const IconButton: FC<{src: string; onClick: () => void}> = ({src, onClick}) => (
  <IconContainer onClick={onClick}>
    <IconButtonImage src={src} />
  </IconContainer>
);

export const StyledRadioSwitcher = styled(RadioSwitcher)`
  & > * {
    cursor: pointer;
  }
`;

const getDefaultAvatarSetup = (setup: AvatarSetup): {[key: string]: string} => {
  const baseSettings = setup.reduce((acc: {[key: string]: string}, s) => {
    const {values, defaultValue} = s;
    if (values) {
      const valueGroup = values[defaultValue] ?? {};
      return {...acc, ...valueGroup};
    }
    return acc;
  }, {});

  return setup.reduce((acc: {[key: string]: string}, s) => {
    const {key, values, options, defaultValue, isExclusion} = s;
    if (!values && !acc[key]) {
      if (isExclusion) {
        return {
          ...acc,
          [key]: Object.keys(options).reduce(
            (acc, key, idx) => `${acc}${idx ? ',' : ''}${key}`,
            ''
          ),
        };
      }
      return {...acc, [key]: defaultValue ?? ''};
    }
    return acc;
  }, baseSettings);
};

interface OwnProps {
  themeName?: string;
  id: string;
  details: DataItem;
  source?: Source;
  isCover?: boolean;
  disabled?: boolean;
  templateText?: string;
  onClose: () => void;
  onCategoryAdd?: (
    categories: {[id: string]: true},
    override?: boolean
  ) => void;
  onTagSearch?: (text: string) => void;
  onModelAdd: (url: string, modelData: any) => void;
  onFontAdd: (url: string, fileName: string) => void;
}

const AssetDetailsPopup: FC<OwnProps> = ({
  themeName,
  id,
  details,
  source,
  isCover,
  disabled,
  templateText,
  onClose,
  onCategoryAdd,
  onFontAdd,
  onTagSearch,
  onModelAdd,
}) => {
  const {
    name,
    description,
    categories,
    authors,
    tags,
    cover,
    file,
    license,
    variants,
    subsets,
  } = details;
  const [showMainLoader, setShowMainLoader] = useState(
    source === Source.ReadyPlayer
  );
  const [imageView, setImageView] = useState(true);
  const [contrast, setContrast] = useState(false);
  const [fontVariant, setFontVariant] = useState('regular');
  const [fontSubsets, setFontSubsets] = useState<{[id: string]: true}>({
    latin: true,
  });
  const [fontCharacters, setFontCharacters] = useState('');
  const [fontIsLoading, setFontIsLoading] = useState(false);
  const [resolution, setResolution] = useState<ModelResolution>('1k');
  const [resolutionOptions, setResolutionOptions] = useState<ModelResolution[]>(
    ['1k']
  );
  const [avatarSettings, setAvatarSettings] = useState<{[key: string]: string}>(
    getDefaultAvatarSetup(avatarSetup)
  );
  const avatarSettingsRef = useRef(avatarSettings);
  avatarSettingsRef.current = avatarSettings;

  const modelUrl = useRef<string | null>(null);
  const modelData = useRef<any | null>(null);

  const sketchfabViewerRef = useRef<HTMLIFrameElement | null>(null);
  const rightPanelRef = useRef<HTMLDivElement | null>(null);

  const isMounted = useRef(true);

  modelUrl.current =
    file ??
    modelData.current?.gltf?.[resolution]?.gltf.url ??
    modelData.current?.hdri?.[resolution]?.hdr.url ??
    null;

  if (source === Source.ReadyPlayer) {
    modelUrl.current = getReadyPlayerLink(modelUrl.current, avatarSettings);
  }

  const [readyPlayerStore, setReadyPlayerStore] = useSerializedState<{
    [key: string]: string;
  }>({}, 'zee-ready-player-model-settings');

  useEffect(() => {
    if (
      source === Source.ReadyPlayer &&
      readyPlayerStore &&
      Object.keys(readyPlayerStore).length
    ) {
      setAvatarSettings(readyPlayerStore);
    }
  }, [readyPlayerStore]);

  useEffect(() => {
    if (source === Source.PolyHaven) {
      const request = `https://api.polyhaven.com/files/${id}`;
      loadData(request, itemData => {
        modelData.current = itemData;
        const resTarget = itemData?.gltf ?? itemData?.hdri;
        if (resTarget) {
          setResolutionOptions(Object.keys(resTarget) as ModelResolution[]);
        }
      });
    }

    if (source === Source.Avaturn) {
      setImageView(false);
    }

    isMounted.current = true;

    return () => {
      isMounted.current = false;
      if (source === Source.ReadyPlayer) {
        setReadyPlayerStore(avatarSettingsRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (imageView) {
      sketchfabViewerRef.current = null;
      if (source === Source.ReadyPlayer) {
        setShowMainLoader(true);
      }
    } else {
      if (showMainLoader) {
        setShowMainLoader(false);
      }
    }
  }, [imageView]);

  useEffect(() => {
    if (source === Source.GoogleFonts) {
      const familyRequest = `${name}${fontVariant ? `:${fontVariant}` : ''}${
        fontSubsets.length
          ? `:${Object.keys(fontSubsets).reduce(
              (acc: string, key, idx, arr) =>
                `${acc}${key}${idx !== arr.length - 1 ? ',' : ''}`,
              ''
            )}`
          : ''
      }`;

      setFontIsLoading(true);

      WebFont.load({
        google: {
          families: [familyRequest],
        },
        fontactive: () => {
          if (isMounted.current) {
            setFontIsLoading(false);
          }
        },
      });
    }
  }, [fontVariant, fontSubsets]);

  const addItem = async () => {
    if (isFont) {
      let url = `https://fonts.googleapis.com/css?family=${encodeURIComponent(
        name
      )}:${encodeURIComponent(fontVariant)}`;
      let subsetStr = '';
      const subsetKeys = Object.keys(fontSubsets);
      // latin is the default subset, so we don't have to specify it in case it's alone
      if (
        subsetKeys.length &&
        !(subsetKeys.length === 1 && subsetKeys[0] === 'latin')
      ) {
        subsetStr = subsetKeys.reduce(
          (acc: string, key: string, idx) => `${acc}${idx ? ',' : ''}${key}`,
          ''
        );
        url = `${url}&subset=${subsetStr}`;
      }
      if (fontCharacters) {
        url = `${url}&text=${encodeURIComponent(fontCharacters)}`;
      }
      const characterHash = fontCharacters
        ? await getHashString(fontCharacters)
        : '';

      const filename = `${name.split(' ').join('-')}${
        fontVariant ? `_${fontVariant}` : ''
      }${subsetStr ? `_${subsetStr}` : ''}${
        fontCharacters ? `_subset-${characterHash}` : ''
      }`;

      onFontAdd(url, filename);
    } else {
      onModelAdd(modelUrl.current, modelData.current);
    }
  };

  const onSketchfabViewerRef = (ref: HTMLIFrameElement | null) => {
    if (ref && !sketchfabViewerRef.current) {
      sketchfabViewerRef.current = ref;
      if (rightPanelRef.current) {
        const bounds = rightPanelRef.current.getBoundingClientRect();
        if (bounds) {
          sketchfabViewerRef.current.width = bounds.width.toString();
          sketchfabViewerRef.current.height = bounds.height.toString();
        }
      }
      initSketchfabViewer();
    }
  };

  const initSketchfabViewer = () => {
    const client = new Sketchfab('1.12.1', sketchfabViewerRef.current);
    client.init(id, {
      success: () => {},
      error: () => {},
      camera: 0,
      blending: 1,
      autospin: 0,
      autostart: 1,
      transparent: 1,
      annotations_visible: 0,
      annotation_tooltip_visible: 0,
    });
  };

  const buttonText =
    source === Source.PolyHaven
      ? `Add To Project (${resolution})`
      : source === Source.Sketchfab
      ? 'Login to Sketchfab to download'
      : 'Add To Project';

  const isFont = sourceByType[SourceType.Fonts].includes(source);

  const isItalic: boolean = fontVariant.includes('italic');
  const variantWithoutWords = fontVariant
    .replace('italic', '')
    .replace('regular', '');

  const weight: string =
    variantWithoutWords === '' ? '400' : variantWithoutWords;

  const sourceDetails =
    source && sourceAssets[source]
      ? {
          url: sourceAssets[source].url,
          name: Source[source],
          logo:
            themeName === Theme.Light
              ? sourceAssets[source].logoLight
              : sourceAssets[source].logo,
        }
      : undefined;

  const onAvatarSetting = (changes: {[key: string]: string}) => {
    Object.keys(changes).forEach(valueId => {
      const setup = avatarSetup.reduce(
        (acc: AvatarSetupItem | null, curr) =>
          curr.key === valueId ? curr : acc,
        null
      );
      if (setup) {
        const {values} = setup;
        if (values) {
          setAvatarSettings(s => ({...s, ...values[changes[valueId]]}));
        } else {
          setAvatarSettings(s => ({...s, [valueId]: changes[valueId]}));
        }
      }
    });
  };

  const coverIsLoaded = () => {
    if (showMainLoader) {
      setShowMainLoader(false);
    }
  };

  return (
    <Popup onClose={onClose}>
      <LeftPanel>
        <AssetDetails
          themeName={themeName}
          variant={isFont ? 'font' : 'model'}
          disabled={disabled}
          name={name}
          description={description}
          categories={categories}
          authors={authors}
          tags={tags}
          license={license}
          sourceDetails={sourceDetails}
          resolution={resolution}
          resolutionOptions={
            source === Source.PolyHaven ? resolutionOptions : undefined
          }
          setResolution={res => setResolution(res as ModelResolution)}
          buttonText={buttonText}
          onButtonClick={addItem}
          onClose={onClose}
          onTagSearch={onTagSearch}
          onCategoryAdd={onCategoryAdd}
        >
          {source === Source.GoogleFonts ? (
            <FontSettings
              themeName={themeName}
              variants={variants}
              subsets={subsets}
              fontVariant={fontVariant}
              fontSubsets={fontSubsets}
              fontCharacters={fontCharacters}
              setFontVariant={setFontVariant}
              setFontSubsets={setFontSubsets}
              setFontCharacters={setFontCharacters}
            />
          ) : null}
          {source === Source.ReadyPlayer ? (
            <AvatarSettings
              avatarSettings={avatarSettings}
              onAvatarSetting={onAvatarSetting}
            />
          ) : null}
        </AssetDetails>
      </LeftPanel>
      <RightPanel ref={rightPanelRef} contrast={contrast}>
        {showMainLoader && <Loader />}
        {isFont ? (
          <FontPreview
            templateText={fontCharacters || templateText}
            family={name}
            weight={weight}
            isItalic={isItalic}
            isLoading={fontIsLoading}
          />
        ) : imageView ? (
          <Image cover={isCover} src={cover} onLoad={coverIsLoaded}></Image>
        ) : modelData.current?.hdri ? (
          <FullImageView>
            <Image scroll={true} src={cover}></Image>
          </FullImageView>
        ) : source === Source.Sketchfab ? (
          <SketchfabViewer ref={onSketchfabViewerRef}></SketchfabViewer>
        ) : (
          <ModelPreview
            key={`${modelUrl.current}-${resolution}`}
            modelUrl={modelUrl.current}
            modelData={modelData.current}
            scale={1}
            source={source}
          />
        )}

        <BottomNav>
          <IconButton
            src={isFont ? iconContrast : imageView ? icon3D : iconImage}
            onClick={
              isFont ? () => setContrast(x => !x) : () => setImageView(x => !x)
            }
          />
        </BottomNav>
      </RightPanel>
    </Popup>
  );
};

export default AssetDetailsPopup;
