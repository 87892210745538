import React, {FC} from 'react';
import Popup from './popup';
import {Button, HeaderBar, Loader} from '@zappar/foundry-react-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextBox = styled.div`
  padding: 0px 24px;
  flex: 1;
`;
const Buttons = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 12px;
  * {
    margin-right: 12px;
  }
`;

interface OwnProps {
  loading: boolean;
  onClose?: () => void;
}

const ImportProcessPopup: FC<OwnProps> = ({loading, onClose}) => {
  return (
    <Popup width="400px" height="148px" onClose={onClose}>
      <Container>
        <HeaderBar title={loading ? 'Importing asset...' : 'Asset imported'} />
        {loading ? (
          <Loader />
        ) : (
          <>
            <TextBox>
              {'The asset has now been imported into your project'}
            </TextBox>
            <Buttons>
              <Button variant="secondary" onClick={onClose}>
                {'Continue Browsing'}
              </Button>
              <Button variant="primary" onClick={() => window.close()}>
                {'Close Importer'}
              </Button>
            </Buttons>
          </>
        )}
      </Container>
    </Popup>
  );
};

export default ImportProcessPopup;
