import {Loader} from '@zappar/foundry-react-components';
import React from 'react';
import styled from 'styled-components';

const previewFontSizes = [84, 72, 60, 48, 36, 30, 24, 18, 16, 14, 10];

const PreviewContainer = styled.div`
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
`;
const PreviewWrapper = styled.div`
  position: relative;
  padding: 14px 40px;
  line-height: 1.5;
`;

const PreviewLine = styled.div<{
  family: string;
  size: number;
  weight?: string;
  isItalic: boolean;
}>`
  white-space: nowrap;
  font-family: ${props => props.family}, sans-serif;
  font-size: ${props => props.size}px;
  font-weight: ${props => props.weight};
  font-style: ${props => (props.isItalic ? 'italic' : 'normal')};
`;

const SizeWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const SizeText = styled.div`
  font-size: 16px;
  opacity: 0.4;
  margin-right: 11px;
`;

interface FontPreviewProps {
  family: string;
  templateText?: string;
  weight?: string;
  isItalic?: boolean;
  isLoading?: boolean;
}

const FontPreview: React.FC<FontPreviewProps> = ({
  family,
  templateText,
  weight,
  isItalic,
  isLoading,
}) => (
  <PreviewContainer>
    {isLoading ? (
      <Loader />
    ) : (
      <PreviewWrapper>
        {previewFontSizes.map(size => (
          <SizeWrapper key={size}>
            <SizeText>{`${size}px`}</SizeText>
            <PreviewLine
              family={family}
              size={size}
              weight={weight}
              isItalic={isItalic}
            >
              {templateText}
            </PreviewLine>
          </SizeWrapper>
        ))}
      </PreviewWrapper>
    )}
  </PreviewContainer>
);

export default FontPreview;
