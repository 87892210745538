import {RadioSwitcher} from '@zappar/foundry-react-components';
import React, {FC, useRef} from 'react';
import styled from 'styled-components';

import {Theme} from '../types';
import {boxShadow} from '../utils';

const PopupContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const PopupBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.background};
  opacity: 0.8;
`;

const PopupCard = styled.div<{width?: string; height?: string}>`
  position: relative;
  width: 80%;
  height: 80%;
  max-width: ${props => props.width ?? 'unset'};
  max-height: ${props => props.height ?? 'unset'};
  background-color: ${props => props.theme.colors.panelBackground};
  display: flex;
  box-shadow: ${boxShadow};
  outline: none !important;
  :focus {
    outline: none;
  }
`;

export const StyledRadioSwitcher = styled(RadioSwitcher)`
  & > * {
    cursor: pointer;
  }
`;

interface OwnProps {
  theme?: Theme;
  children: React.ReactNode;
  width?: string;
  height?: string;
  onClose: () => void;
}

const Popup: FC<OwnProps> = ({children, width, height, onClose}) => {
  const cardFocused = useRef(false);

  const onCardRef = (ref: HTMLDivElement | null) => {
    if (!cardFocused.current && ref) {
      cardFocused.current = true;
      ref.focus();
    }
  };
  return (
    <PopupContainer
      onKeyDown={evt => {
        if (evt.code === 'Escape') onClose();
      }}
    >
      <PopupBackground onClick={onClose} />
      <PopupCard width={width} height={height} ref={onCardRef} tabIndex={-1}>
        {children}
      </PopupCard>
    </PopupContainer>
  );
};

export default Popup;
