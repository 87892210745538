import React, {FC} from 'react';
import {AvatarSetup, AvatarSetupItem} from '../types';
import {SettingContainer, Title} from '../utils';
import CustomRadioButton from './customradiobutton';
import {Select} from '@zappar/foundry-react-components';
import styled from 'styled-components';

const AvatarSettingsContainer = styled.div`
  margin-top: 16px;
`;
const Details = styled.details`
  font-weight: 600;
  width: 100%;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
`;

const DetailsContainer = styled.div`
  margin-top: 36px;
`;

export const avatarSetup: AvatarSetup = [
  {
    key: 'quality',
    name: 'Quality',
    defaultValue: 'medium',
    options: {
      low: 'Low - (lod=2, textureSizeLimit=256, textureAtlas=256)',
      medium: 'Medium - (lod=1, textureSizeLimit=512, textureAtlas=512)',
      high: 'High - (lod=0, textureSizeLimit=1024, textureAtlas=1024)',
      custom: 'Custom',
    },
    values: {
      low: {
        lod: '2',
        textureSizeLimit: '256',
        textureAtlas: '256',
      },
      medium: {
        lod: '1',
        textureSizeLimit: '512',
        textureAtlas: '512',
      },
      high: {
        lod: '0',
        textureSizeLimit: '1024',
        textureAtlas: '1024',
      },
    },
    twoLines: true,
  },
  /* {
    key: 'compression',
    name: 'Apply Compression',
    defaultValue: 'true',
    options: {
      true: 'ON - (useMeshOptCompression=true, textureFormat=webp)',
      false: 'OFF- (useMeshOptCompression=false, textureFormat=png)',
      custom: 'Custom',
    },
    values: {
      true: {
        useMeshOptCompression: 'true',
        textureFormat: 'webp',
      },
      false: {
        useMeshOptCompression: 'false',
        textureFormat: 'png',
      },
    },
    twoLines: true,
  }, */
  {
    key: 'textureFormat',
    name: 'Texture Format',
    defaultValue: 'webp',
    note: 'Using webp encoding can result in up to 40% reduction in avatar file size.',
    options: {
      webp: 'webp',
      jpeg: 'jpeg',
      png: 'png',
    },
  },
  {
    key: 'lod',
    name: 'LOD',
    defaultValue: '0',
    options: {
      '0': 'No triangle count reduction is applied',
      '1': 'Retain 50% of the original triangle count',
      '2': 'Retain 25% of the original triangle count',
    },
    isDetail: true,
  },
  {
    key: 'textureSizeLimit',
    name: 'Texture Size Limit',
    defaultValue: '1024',
    options: {
      '256': '256',
      '512': '512',
      '1024': '1024',
    },
    isDetail: true,
  },
  {
    key: 'textureAtlas',
    name: 'Texture Atlas',
    defaultValue: 'none',
    options: {
      none: 'Do not create a texture atlas',
      '256': ' Create a texture atlas of 256x256px',
      '512': 'Create a texture atlas of 512x512px',
      '1024': 'Create a texture atlas of 1024x1024px',
    },
    isDetail: true,
  },
  {
    key: 'textureChannels',
    name: 'Texture Channels',
    options: {
      baseColor: 'Base Color',
      normal: 'Normal',
      metallicRoughness: 'Metallic Roughness',
      emissive: 'Emissive',
      occlusion: 'Occlusion',
    },
    isDetail: true,
    isMultiSelect: true,
    isExclusion: true,
  },
  {
    key: 'pose',
    name: 'Pose',
    defaultValue: 'A',
    options: {
      A: 'Create a full-body avatar in A pose',
      T: 'Create a full-body avatar in T pose',
    },
    isDetail: true,
  },
  /* {
    key: 'useDracoMeshCompression',
    name: 'Draco Mesh Compression',
    defaultValue: 'false',
    options: {
      false: 'No compression',
      true: 'Enable compression',
    },
    isDetail: true,
  },
  {
    key: 'useMeshOptCompression',
    name: 'Mesh Optimization Compression',
    defaultValue: 'false',
    options: {
      false: 'No compression',
      true: 'Enable compression',
    },
    isDetail: true,
  },*/
  {
    key: 'useHands',
    name: 'Use Hands on Half-body VR Avatars',
    defaultValue: 'true',
    options: {
      false: 'Do not include hands with half-body VR avatars',
      true: 'Include hands with half-body VR avatars',
    },
    isDetail: true,
  },
];

const isActiveValuesSetting = (values, valueKey, avatarSettings) =>
  Object.keys(values[valueKey]).reduce(
    (isActive: boolean | null, testKey: string) =>
      avatarSettings[testKey] &&
      avatarSettings[testKey] === values[valueKey][testKey]
        ? isActive !== false
          ? true
          : false
        : false,
    null
  );

interface AvatarSettingProps {
  setup: AvatarSetupItem;
  avatarSettings: {[key: string]: string};
  onAvatarSetting: (values: {[key: string]: string}) => void;
}

const AvatarSetting: FC<AvatarSettingProps> = ({
  setup,
  avatarSettings,
  onAvatarSetting,
}) => {
  const {key, name, options, defaultValue, values, isMultiSelect, twoLines} =
    setup;

  let active = '';
  if (values) {
    active = Object.keys(values).reduce(
      (acc, valueKey) =>
        isActiveValuesSetting(values, valueKey, avatarSettings)
          ? valueKey
          : acc,
      'custom'
    );
  } else if (avatarSettings?.[key]) {
    active = avatarSettings[key];
  } else if (defaultValue) {
    active = defaultValue;
  }

  return (
    <SettingContainer>
      <Title>{name}</Title>
      {isMultiSelect ? (
        Object.keys(options ?? {}).map((optionKey: string) => {
          const isActive = avatarSettings[key].split(',').includes(optionKey);
          return (
            <CustomRadioButton
              key={optionKey}
              title={options[optionKey]}
              checked={isActive}
              onChecked={() => {
                const keyList = avatarSettings[key].split(',');
                const newKeyList = keyList.includes(optionKey)
                  ? keyList.filter(key => key !== optionKey).join(',')
                  : [...keyList, optionKey].join(',');

                onAvatarSetting({[key]: newKeyList});
              }}
            />
          );
        })
      ) : (
        <Select
          key="select"
          value={active}
          onChange={evt => onAvatarSetting({[key]: evt.target.value})}
          style={
            twoLines
              ? {height: '48px', whiteSpace: 'normal', textOverflow: 'ellipsis'}
              : undefined
          }
        >
          {Object.keys(options ?? {}).map((optionKey: string) =>
            optionKey === 'custom' && active !== 'custom' ? null : (
              <option key={optionKey} value={optionKey}>
                {options[optionKey]}
              </option>
            )
          )}
        </Select>
      )}
    </SettingContainer>
  );
};

interface AvatarSettingsProps {
  avatarSettings: {[key: string]: string};
  onAvatarSetting: (values: {[key: string]: string}) => void;
}

const AvatarSettings: FC<AvatarSettingsProps> = ({
  avatarSettings,
  onAvatarSetting,
}) => {
  const avatarSetupMain = avatarSetup.filter(item => !item.isDetail);
  const avatarSetupDetails = avatarSetup.filter(item => item.isDetail);

  return (
    <AvatarSettingsContainer>
      {avatarSetupMain.map((setup: AvatarSetupItem) => (
        <AvatarSetting
          setup={setup}
          avatarSettings={avatarSettings}
          onAvatarSetting={onAvatarSetting}
        />
      ))}
      <Details>
        <summary>Advanced</summary>
        <DetailsContainer>
          {avatarSetupDetails.map((setup: AvatarSetupItem) => (
            <AvatarSetting
              key={setup.key}
              setup={setup}
              avatarSettings={avatarSettings}
              onAvatarSetting={onAvatarSetting}
            />
          ))}
        </DetailsContainer>
      </Details>
    </AvatarSettingsContainer>
  );
};

export default AvatarSettings;
