// @ts-nocheck
// @
import {ThemeDark} from '@zappar/foundry-react-components/lib/theme';

var __assign = // eslint-disable-line
  (this && this.__assign) ||
  function () {
    // @ts-ignore
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) { // eslint-disable-line
          s = arguments[i]; // eslint-disable-line
          for (const p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments); // eslint-disable-line
  };

export const ThemeDesigner = {
  colors: __assign(__assign({}, ThemeDark.colors), {
    inactiveSelectionBackground: 'rgb(223, 223, 223)',
    inactiveSelectionFontColor: '#616161',
    activeSelectionBackground: '#0060c0',
    hoverBackground: '#e8e8e8',
    hoverForeground: '#616161',
    background: '#ffffff',
    panelBackground: '#ffffff',
    panelBoxBackground: '#f8f8f8',
    tabSelected: 'black',
    fontColor: '#616161',
    placeholderFontColor: 'rgb(220, 220, 220)',
    menuBackgroundColor: '#f3f3f3',
    menuKeyboardHint: '#d4d4d4',
    menuEmptyColor: '#d4d4d4',
    selectedTreeItemFontColor: 'white',
    statusBarIconColor: 'white',
    leftNavColor: '#e3e3e3',
    splitterBorder: '#e1e1e1',
    buttonColor: '#777777',
    buttonHoverColor: '#999999',
    buttonFontColor: '#4a90e2',
    buttonPrimaryColor: '#ffffff',
    buttonPrimaryHoverColor: '#4e8cc7',
    buttonPrimaryFontColor: 'white',
    buttonInlineHoverColor: 'none',
    buttonInlineDisabledFontColor: '#d2d2d2',
    buttonDisabledFontColor: '#ffffff',
    buttonDisabledColor: '#a4c6f1',
    searchReplaceRemoveBackground: '#f5c2c2',
    searchReplaceInsertBackground: '#e1e7d3',
    inputValidationErrorBackground: '#f2dede',
    inactiveInputFontColor: '#616161',
    inputFontColor: '#616161',
    inputBackgroundColor: 'white',
    activeSelectionFontColor: 'white',
    statusBarBackground: '#0060c0',
    toggleButtonActiveBackground: '#cce9fc',
    editorTabBackground: '#ececec',
    editorTabBackgroundSelected: 'white',
    infoPanelBorderColor: '#d7d7d7',
    infoPanelFilterBackground: '#D7D7DB',
    infoPanelFilterHoverBackground: '#c7c7cc',
    infoPanelFilterFontColor: '#4E4E51',
    infoPanelLogFontColor: 'white',
    notificationBackground: '#e1e1e1',
    subheadingColor: '#bfbfbf',
    logLineSeparator: '#f6f6f6',
    highlight: '#0084ff',
    emptyStateColor: '#d0d0d0',
    documentationBackground: 'white',
  }),
  defaultTransition: '0.2s cubic-bezier(0, 0.6, 0.6, 1)',
};
