import React, {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Source, SourceType} from '../types';
import {sourceAssets, sourceByType} from '../utils';

import {Loader} from '@zappar/foundry-react-components';
import WebFont from 'webfontloader';

const AssetItem = styled.div<{passive?: boolean}>`
  position: relative;
  overflow: auto;
  width: 33.333%;
  height: 288px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
  ${props => (props.passive ? 'opacity: 0.3;' : '')}
`;

const AssetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 94%;
  height: 94%;
  overflow: hidden;
  border-radius: 6px;
`;

const ImageContainer = styled.div`
  position: relative;
  flex: 1;
  background-color: ${props => props.theme.colors.menuBackgroundColor};
  max-height: 222px;
  overflow: hidden;
`;

const NameContainer = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 46px;
  font-size: 13px;
  background-color: ${props => props.theme.colors.hoverBackground};
`;

const CardImage = styled.img<{isCover?: boolean}>`
  width: ${props => (props.isCover ? '100%' : 'calc(100% - 16px)')};
  height: ${props => (props.isCover ? '100%' : 'calc(100% - 16px)')};
  object-fit: ${props => (props.isCover ? 'cover' : 'contain')};
  position: absolute;
  margin-left: ${props => (props.isCover ? '0' : '8px')};
  margin-top: ${props => (props.isCover ? '0' : '8px')};
`;

const FontPreview = styled.div<{family?: string}>`
  font-family: ${props => props.family};
  font-display: block;
  font-size: 40px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 20px 12px 0 24px;
  line-height: 1;
`;

const SourceIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: auto;
  height: auto;
  max-width: 120px;
  max-height: 32px;
`;

interface OwnProps {
  id: string;
  name: string;
  passive?: boolean;
  source?: Source;
  img?: string;
  fontFamily?: string;
  templateText?: string;
  onHover: (key: string) => void;
  onClick: (key: string) => void;
}

const AssetCard: FC<OwnProps> = ({
  id,
  name,
  passive,
  source,
  img,
  fontFamily,
  templateText,
  onHover,
  onClick,
}) => {
  const [fontIsLoaded, setFontIsLoaded] = useState(false);

  useEffect(() => {
    if (source === Source.GoogleFonts) {
      WebFont.load({
        google: {
          families: [fontFamily],
        },
        fontactive: () => {
          setFontIsLoaded(true);
        },
      });
    }
  }, [fontFamily]);

  return (
    <AssetItem
      passive={passive}
      onMouseMove={() => onHover(id)}
      onClick={() => onClick(id)}
    >
      <AssetWrapper>
        <ImageContainer>
          {sourceByType[SourceType.Models].includes(source) ? (
            <CardImage isCover={source !== Source.PolyHaven} src={img} />
          ) : fontIsLoaded ? (
            <FontPreview family={fontFamily}>{templateText}</FontPreview>
          ) : (
            <Loader />
          )}
        </ImageContainer>
        <NameContainer>
          {name}
          <SourceIcon src={sourceAssets[source].icon} />
        </NameContainer>
      </AssetWrapper>
    </AssetItem>
  );
};

export default AssetCard;
