// Direct resource from Web Components repo

import {GLTF} from './gltf2';

export enum OutputErrorCode {
  INVALID_GLTF = 'INVALID_GLTF',
  MISSING_FILES = 'MISSING_FILES',
}

export interface OutputError {
  errorCode: OutputErrorCode;
}

export interface OutputInvalidError extends OutputError {
  error: Error;
}

export interface OutputMissingFilesError extends OutputError {
  gltf: IncompleteGltf;
}

export interface LoadingResourcesByUri {
  [uri: string]: Uint8Array | null | undefined;
}
export interface ResourcesByUri {
  [uri: string]: Uint8Array;
}

export interface ParsedGLTFOutput {
  gltf: GLTF;
  resourcesByUri: ResourcesByUri;
}

export interface ResouceData {
  uri: string;
  bytes: Uint8Array;
}

export interface zipParseOutput {
  gltfs: {uri: string; json: GLTF; resourcesByUri: ResourcesByUri}[];
  glbs: File[];
}

export interface HandlerEventMap {
  'gltf:error': CustomEvent<OutputError>;
  'gltf:glb': CustomEvent<File>;
  'gltf:unusedFiles': CustomEvent<File[]>;
}

export interface IModel3DUploadHandler extends EventTarget {
  addEventListener<K extends keyof HandlerEventMap>(
    event: K,
    listener:
      | ((this: IModel3DUploadHandler, ev: HandlerEventMap[K]) => void)
      | null,
    options?: AddEventListenerOptions | boolean
  ): void;
  addEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: AddEventListenerOptions | boolean
  ): void;
}

export interface IncompleteGltf {
  id: string;
  name: string;
  handler: any;
}
