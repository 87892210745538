/**
 * A type-safe event handling class that multiple functions to be registered to be called when events are emitted.
 */
export class Event {
  private _funcs: (() => void)[] = [];
  private _debounced: (() => void)[] = [];

  constructor(private _debounceTimeout = 500) {
    this._emitDebounced = this._emitDebounced.bind(this);
  }

  clear() {
    this._funcs = [];
    this._debounced = [];
  }

  /**
   * Bind new handler function.
   * @param f - The callback function to be bound.
   */
  bindfn(f: () => void): void {
    this._funcs.push(f);
  }

  bindDebounced(f: () => void): void {
    this._debounced.push(f);
  }

  /**
   * Unbind an existing handler function.
   * @param f - The callback function to be unbound.
   */
  unbind(f: () => void): void {
    const indx = this._funcs.indexOf(f);
    if (indx > -1) {
      this._funcs.splice(indx, 1);
    }
  }

  unbindDeboounced(f: () => void): void {
    const indx = this._debounced.indexOf(f);
    if (indx > -1) {
      this._debounced.splice(indx, 1);
    }
  }

  private _pendingEmit = false;

  /**
   * Emit an event, calling the bound handler functions.
   */
  emit(): void {
    for (let i = 0, total = this._funcs.length; i < total; i++) {
      try {
        this._funcs[i]();
      } catch (ex) {
        console.error('Error processing event handler', ex);
      }
    }
    if (!this._pendingEmit) {
      this._pendingEmit = true;
      setTimeout(this._emitDebounced, this._debounceTimeout);
    }
  }

  private _emitDebounced() {
    this._pendingEmit = false;
    for (let i = 0, total = this._debounced.length; i < total; i++) {
      try {
        this._debounced[i]();
      } catch (ex) {
        console.error('Error processing event handler', ex);
      }
    }
  }
}

/**
 * A type-safe event handling class that multiple functions to be registered to be called when events are emitted.
 * This class will pass a single argument supplied to [[emit]] to the handler functions.
 *
 * @typeparam A - The type of the argument passed to the handler functions through [[emit]].
 */
export class Event1<A> {
  private _funcs: ((a: A) => void)[] = [];
  private _debounced: ((a: A[]) => void)[] = [];
  private _funcsByToken = new Map<number, (a: A) => void>();
  private _latestToken = 1;

  constructor() {
    this._emitDebounced = this._emitDebounced.bind(this);
  }

  hasFns() {
    return this._funcs.length > 0;
  }

  clear() {
    this._funcs = [];
    this._debounced = [];
  }

  /**
   * Bind new handler function.
   * @param f - The callback function to be bound.
   */
  bindfn(f: (a: A) => void): number {
    const token = this._latestToken++;
    this._funcsByToken.set(token, f);
    this._funcs.push(f);
    return token;
  }

  bindDebounced(f: (a: A[]) => void): void {
    this._debounced.push(f);
  }

  unbindDeboounced(f: (a: A[]) => void): void {
    const indx = this._debounced.indexOf(f);
    if (indx > -1) {
      this._debounced.splice(indx, 1);
    }
  }

  unbindToken(t: number): void {
    const f = this._funcsByToken.get(t);
    if (!f) return;
    const indx = this._funcs.indexOf(f);
    if (indx > -1) {
      this._funcs.splice(indx, 1);
    }
  }

  /**
   * Unbind an existing function.
   * @param f - The callback function to be unbound.
   */
  unbindfn(f: (a: A) => void): void {
    const indx = this._funcs.indexOf(f);
    if (indx > -1) {
      this._funcs.splice(indx, 1);
    }
  }

  private _pendingEmit = false;
  private _toEmit: A[] = [];
  /**
   * Emit an event.
   *
   * @param a - The argument to pass to handler functions.
   */
  emit(a: A): void {
    for (let i = 0, total = this._funcs.length; i < total; i++) {
      try {
        this._funcs[i](a);
      } catch (ex) {
        console.error('Error processing event handler', ex);
      }
    }
    this._toEmit.push(a);

    if (!this._pendingEmit) {
      this._pendingEmit = true;
      setTimeout(this._emitDebounced, 500);
    }
  }

  private _emitDebounced() {
    this._pendingEmit = false;
    for (let i = 0, total = this._debounced.length; i < total; i++) {
      try {
        this._debounced[i](this._toEmit);
      } catch (ex) {
        console.error('Error processing event handler', ex);
      }
    }
    this._toEmit = [];
  }
}

export class Event2<A, B> {
  private _debounced: ((a: [A, B][]) => void)[] = [];
  private _funcs: ((a: A, b: B) => void)[] = [];

  constructor() {
    this._emitDebounced = this._emitDebounced.bind(this);
  }

  hasFns() {
    return this._funcs.length > 0;
  }

  clear() {
    this._funcs = [];
    this._debounced = [];
  }

  /**
   * Bind new handler function.
   * @param f - The callback function to be bound.
   */
  bindfn(f: (a: A, b: B) => void): void {
    this._funcs.push(f);
  }

  /**
   * Unbind an existing function.
   * @param f - The callback function to be unbound.
   */
  unbindfn(f: (a: A, b: B) => void): void {
    const indx = this._funcs.indexOf(f);
    if (indx > -1) {
      this._funcs.splice(indx, 1);
    }
  }

  unbindDeboounced(f: (a: [A, B][]) => void): void {
    const indx = this._debounced.indexOf(f);
    if (indx > -1) {
      this._debounced.splice(indx, 1);
    }
  }

  private _pendingEmit = false;
  private _toEmit: [A, B][] = [];

  /**
   * Emit an event.
   *
   * @param a - The argument to pass to handler functions.
   */
  emit(a: A, b: B): void {
    for (let i = 0, total = this._funcs.length; i < total; i++) {
      try {
        this._funcs[i](a, b);
      } catch (ex) {
        console.error('Error processing event handler', ex);
      }
    }
    this._toEmit.push([a, b]);

    if (!this._pendingEmit) {
      this._pendingEmit = true;
      setTimeout(this._emitDebounced, 500);
    }
  }

  bindDebounced(f: (a: [A, B][]) => void): void {
    this._debounced.push(f);
  }

  private _emitDebounced() {
    this._pendingEmit = false;
    for (let i = 0, total = this._debounced.length; i < total; i++) {
      try {
        this._debounced[i](this._toEmit);
      } catch (ex) {
        console.error('Error processing event handler', ex);
      }
    }
    this._toEmit = [];
  }
}

export class Event3<A, B, C> {
  private _funcs: ((a: A, b: B, c: C) => void)[] = [];
  private _debounced: ((a: [A, B, C][]) => void)[] = [];

  constructor() {
    this._emitDebounced = this._emitDebounced.bind(this);
  }

  clear() {
    this._funcs = [];
    this._debounced = [];
  }

  hasFns() {
    return this._funcs.length > 0;
  }

  /**
   * Bind new handler function.
   * @param f - The callback function to be bound.
   */
  bindfn(f: (a: A, b: B, c: C) => void): void {
    this._funcs.push(f);
  }

  bindDebounced(f: (a: [A, B, C][]) => void): void {
    this._debounced.push(f);
  }

  unbindDeboounced(f: (a: [A, B, C][]) => void): void {
    const indx = this._debounced.indexOf(f);
    if (indx > -1) {
      this._debounced.splice(indx, 1);
    }
  }

  /**
   * Unbind an existing function.
   * @param f - The callback function to be unbound.
   */
  unbindfn(f: (a: A, b: B, c: C) => void): void {
    const indx = this._funcs.indexOf(f);
    if (indx > -1) {
      this._funcs.splice(indx, 1);
    }
  }

  private _pendingEmit = false;
  private _toEmit: [A, B, C][] = [];
  /**
   * Emit an event.
   *
   * @param a - The argument to pass to handler functions.
   */
  emit(a: A, b: B, c: C): void {
    for (let i = 0, total = this._funcs.length; i < total; i++) {
      try {
        this._funcs[i](a, b, c);
      } catch (ex) {
        console.error('Error processing event handler', ex);
      }
    }

    this._toEmit.push([a, b, c]);

    if (!this._pendingEmit) {
      this._pendingEmit = true;
      setTimeout(this._emitDebounced, 500);
    }
  }

  private _emitDebounced() {
    this._pendingEmit = false;
    for (let i = 0, total = this._debounced.length; i < total; i++) {
      try {
        this._debounced[i](this._toEmit);
      } catch (ex) {
        console.error('Error processing event handler', ex);
      }
    }
    this._toEmit = [];
  }
}
