/* eslint-disable no-case-declarations */
import {Button} from '@zappar/foundry-react-components';
import React, {FC, useEffect, useRef, useState} from 'react';
import styled, {CSSProperties} from 'styled-components';
import AssetDetailsPopup from './common/assetdetailspopup';
import AssetList from './common/assetlist';
import AvatarGenerator from './common/avatargenerator';
import ImportProcessPopup from './common/importprocesspopup';
import {ThemeDesigner} from './designer-theme';
import {
  getResourcesOfGltf,
  loadFile,
  resourcesToByteArray,
} from './glb_exporter/glb-converter';
import {createGlb} from './glb_exporter/gltf-to-glb';
import AssetFilterUI, {OptionList} from './shared/AssetFilterUI';
import {CategoriesType, Data, Dropdown, Source, SourceType} from './types';
import {
  ConvertGooglefontsCategories,
  ConvertPolyhavenCategories,
  ConvertSketchfabCategories,
  ShortTextInput,
  WIPFilter,
  allowedTabs,
  convertGoogleFontsData,
  convertPolyHavenData,
  convertPolyPizzaData,
  convertSketchfabData,
  filterContent,
  filterSearch,
  gfk,
  googleFontsLimit,
  loadData,
  loadModelData,
  middlewareURL,
  polyPizzaCategories,
  polyPizzaItemLimit,
  post,
  readyPlayerModelApi,
  sketchfabItemLimit,
  sourceByType,
  urlParamMatch,
} from './utils';
import AvatarIntro from './common/avatarintro';

const App = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ThemedApp = styled(App)`
  ${props => {
    return (
      props.theme === ThemeDesigner &&
      `font-size: 14px;
      font-weight: 400;
      font-style: normal;
      color: #344B60;
      line-height: 21px;
      `
    );
  }};
`;

// 1004x610
const MainContainer = styled.div`
  position: relative;
  width: 90%;
  max-width: 1004px; // * 1.2 = 1204px
  height: 100%; // 610px; // * 1.2 = 732px
  background-color: ${props => props.theme.colors.background};
`;

const SearchSettingsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: max-content;
`;

const VertexCountTitle = styled.div`
  padding-right: 8px;
  display: flex;
  opacity: 0.6;
`;

const LoginButton = styled(Button)`
  margin-left: 16px;
`;

interface AssetManagerProps {
  themeName?: string;
  type?: string;
  source?: string;
  allowed?: string[];
}

const AssetManager: FC<AssetManagerProps> = ({
  themeName,
  type,
  source: urlSource,
  allowed,
}) => {
  const initSourceType = urlParamMatch[type];
  const initSource = urlParamMatch[urlSource];

  const [, setRender] = useState(0);
  const [tabs, setTabs] = useState<string[]>(allowedTabs(allowed));
  const [sourceType, setSourceType] = useState<SourceType>(
    initSourceType ? initSourceType : SourceType.Models
  );

  const [source, setSource] = useState<Source>(
    sourceByType[sourceType].includes(initSource)
      ? initSource
      : sourceByType[sourceType][0]
  );
  const sourceRef = useRef(source);
  sourceRef.current = source;

  const [selectedDropdowns, setSelectedDropdowns] = useState({
    [Dropdown.Source]: source,
  });

  const [contentList, setContentList] = useState<Data>({});
  const [contentListWithFilter, setContentListWithFilter] = useState<Data>({});
  const [allContentList, setAllContentList] = useState<Data>({});
  const [allFonts, setAllFonts] = useState<Data>({});
  const [resetHover, setResetHover] = useState(0);
  const [resetUI, setResetUI] = useState(0);
  const [resetSearch, setResetSearch] = useState(0);
  const [resetAvatarGenerator, setResetAvatarGenerator] = useState(0);
  const contentListPage = useRef(0);

  // We need selected categories to be available before re-render
  const categories = useRef<CategoriesType>({});
  const selectedCategories = useRef<string[]>([]);
  const categoriesSource = useRef<Source | null>(null);

  const [contentListLoading, setContentListLoading] = useState(false);
  const [itemLimit, setItemLimit] = useState<number | null>(null);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [serverFilterMode, setServerFilterMode] = useState(false);
  const [serverSearchText, setServerSearchText] = useState('');

  const searchTimeout = useRef(0);
  const noSearchTimeout = useRef(false);
  const updateSearchText = useRef(false);
  const selectedSourceByType = useRef<{[key: string]: Source}>({});

  const [itemDetails, setItemDetails] = useState<null | string>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [importingAsset, setImportingAsset] = useState(false);
  const [reloadList, setReloadList] = useState(0);
  const [vertexCount, setVertexCount] = useState(100000);
  const [templateText, setTemplateText] = useState(
    'the quick brown fox jumped over the lazy dog'
  );

  const dataFetchCount = useRef(0);

  const [importPopup, setImportPopup] = useState(false);

  const scrollValues = useRef<{[id: string]: CSSProperties}>({});
  const isRealScroll = useRef(false);

  // because data load callbacks are out of scope we pick up this state with a useEffect and set it back to null
  const [newTempContentList, setNewTempContentList] =
    useState<Data | null>(null);

  const vertexFieldRef = useRef<HTMLInputElement | null>(null);
  const templateFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const tabs = allowedTabs(allowed);
    setTabs(tabs);
    if (!tabs.includes(sourceType)) {
      setSourceType(tabs[0] as SourceType);
    }
  }, [JSON.stringify(allowed)]);

  useEffect(() => {
    if (serverFilterMode) {
      contentListPage.current = 0;
      setReloadList(x => x + 1);
    }
  }, [selectedCategories.current, serverSearchText]);

  useEffect(() => {
    if (selectedSourceByType[sourceType]) {
      setSource(selectedSourceByType[sourceType]);
    } else if (!sourceByType[sourceType].includes(source)) {
      setSource(sourceByType[sourceType][0]);
    }
  }, [sourceType]);

  useEffect(() => {
    selectedSourceByType[sourceType] = source;
    onDropdown(Dropdown.Source, source);
  }, [source]);

  useEffect(() => {
    scrollValues.current = {};

    const t = Object.keys(urlParamMatch).reduce(
      (acc, key) => (urlParamMatch[key] === sourceType ? key : acc),
      ''
    );

    const s = Object.keys(urlParamMatch).reduce(
      (acc, key) => (urlParamMatch[key] === source ? key : acc),
      ''
    );

    window.history.replaceState(
      {},
      'Asset Importer',
      `${window.location.origin}?theme=${themeName}&type=${t}&source=${s}${
        allowed ? `&allowed=${tabs.join(',')}` : ''
      }`
    );
  }, [sourceType, source]);

  useEffect(() => {
    if (sourceByType[sourceType].includes(source)) {
      categories.current = {};
      categoriesSource.current = null;
      selectedCategories.current = [];
      setContentList({});
      setContentListWithFilter({});
      setAllFonts({});
      // Google fonts are having a mixed mode where the complete list of fonts are loaded but the assets are added incrementally.
      setServerFilterMode(
        source === Source.PolyHaven || source === Source.GoogleFonts
          ? false
          : true
      );
      if (source === Source.PolyHaven) {
        setItemLimit(null);
      } else {
        setItemLimit(
          source === Source.PolyPizza
            ? polyPizzaItemLimit
            : source === Source.GoogleFonts
            ? googleFontsLimit
            : sketchfabItemLimit
        );
      }
      newAssetListRequest();
      setResetUI(x => x + 1);
    }
  }, [sourceType, source]);

  useEffect(() => {
    setResetHover(x => x + 1);
  }, [
    searchText,
    sourceByType,
    source,
    categories,
    selectedCategories.current,
  ]);

  useEffect(() => {
    if (!itemDetails) {
      setResetHover(x => x + 1);
    }
    if (source === Source.ReadyPlayer && itemDetails) {
      setResetAvatarGenerator(x => x + 1);
    }
  }, [itemDetails]);

  useEffect(() => {
    if (allContentList && Object.keys(allFonts).length) {
      handleMoreGoogleFonts();
    }
  }, [allContentList]);

  useEffect(() => {
    clearTimeout(searchTimeout.current);
    if (searchText) {
      if (!noSearchTimeout.current) {
        searchTimeout.current = window.setTimeout(
          applyServerSearch,
          500,
          searchText
        );
      } else {
        noSearchTimeout.current = false;
        applyServerSearch(searchText);
      }

      if (updateSearchText.current) {
        updateSearchText.current = false;
        setResetSearch(x => x + 1);
      }
    } else {
      setServerSearchText('');
    }
  }, [searchText]);

  useEffect(() => {
    if (serverFilterMode) {
      newAssetListRequest();
    }
  }, [reloadList, vertexCount]);

  // effect to bridge out-of-scope callback functions on content load
  useEffect(() => {
    if (newTempContentList) {
      contentListLoaded(newTempContentList);
      setNewTempContentList(null);
    }
  }, [newTempContentList]);

  useEffect(() => {
    if (source === Source.GoogleFonts) {
      contentListPage.current = 0;
      setAllContentList(
        filterSearch(
          searchText,
          filterContent(selectedCategories.current, allFonts)
        )
      );
    } else {
      setContentListWithFilter(
        serverFilterMode
          ? contentList
          : filterSearch(
              searchText,
              filterContent(selectedCategories.current, contentList)
            )
      );
    }
  }, [searchText, selectedCategories.current]);

  const applyServerSearch = (text: string) => setServerSearchText(text);

  // NOTE: out of scope function, the set state is picked up by an effect
  const contentListLoadedOutOfScope = list => {
    setNewTempContentList(list);
  };

  const contentListLoaded = list => {
    if (list) {
      if (source === Source.GoogleFonts) {
        setAllFonts(list);
        setAllContentList(
          filterSearch(
            searchText,
            filterContent(selectedCategories.current, list)
          )
        );
      } else {
        setContentListLoading(false);
        const cList = contentListPage.current
          ? {...contentList, ...list}
          : list;
        setContentList(cList);
        setContentListWithFilter(
          serverFilterMode
            ? cList
            : filterSearch(
                searchText,
                filterContent(selectedCategories.current, cList)
              )
        );

        if (itemLimit && Object.keys(list).length === itemLimit) {
          setShowLoadMore(true);
        } else {
          setShowLoadMore(false);
        }
      }
    } else {
      console.error('Asset list loading has failed!');
    }
  };

  const handleMoreGoogleFonts = () => {
    setContentListLoading(false);
    const shadowList = new Array(
      itemLimit * (contentListPage.current + 1)
    ).fill(null);
    const tempList = shadowList.reduce((acc, _item, idx) => {
      const itemId = Object.keys(allContentList)[idx];
      if (itemId) {
        return {...acc, [itemId]: allContentList[itemId]};
      } else {
        return acc;
      }
    }, {});

    setContentList(tempList);
    setContentListWithFilter(tempList);
    if (shadowList.length === Object.keys(tempList).length) {
      setShowLoadMore(true);
    } else {
      setShowLoadMore(false);
    }
  };

  const loadNextPage = () => {
    contentListPage.current = contentListPage.current + 1;
    if (source === Source.GoogleFonts) {
      handleMoreGoogleFonts();
    } else {
      setReloadList(x => x + 1);
    }
  };

  const newAssetListRequest = async () => {
    let request = '';
    let categoryRequest = '';
    dataFetchCount.current = dataFetchCount.current + 1;
    const currentCount = dataFetchCount.current;
    setContentListLoading(true);

    switch (source) {
      case Source.PolyHaven:
        if (categoriesSource.current !== Source.PolyHaven) {
          categoryRequest = `https://api.polyhaven.com/categories/${sourceType}`;
          loadData(categoryRequest, data => {
            if (data) {
              categories.current = ConvertPolyhavenCategories(data);
              categoriesSource.current = Source.PolyHaven;
              setRender(x => x + 1);
            }
          });
        }

        request = `https://api.polyhaven.com/assets/?t=${sourceType}`;
        loadData(request, data => {
          if (currentCount === dataFetchCount.current) {
            contentListLoadedOutOfScope(
              data ? convertPolyHavenData(data) : data
            );
          }
        });
        break;
      case Source.Sketchfab:
        if (categoriesSource.current !== Source.Sketchfab) {
          categoryRequest = `https://api.sketchfab.com/v3/categories`;
          loadData(categoryRequest, data => {
            if (data) {
              categories.current = ConvertSketchfabCategories(data);
              categoriesSource.current = Source.Sketchfab;
              setRender(x => x + 1);
            }
          });
        }

        request = `https://api.sketchfab.com/v3/search?type=models&downloadable=true&sort_by=-likeCount&archives_flavours=false&archives_max_vertex_count=${vertexCount}&count=${itemLimit}`;
        if (selectedCategories.current.length) {
          request = `${request}&categories=${selectedCategories.current.reduce(
            (acc: string, cat: string) => `${acc},${categories.current[cat]}`,
            ''
          )}`;
        }
        if (serverSearchText) {
          request = `${request}&q=${serverSearchText}`;
        }
        if (contentListPage.current) {
          request = `${request}&cursor=${contentListPage.current * itemLimit}`;
        }
        loadData(request, data => {
          if (currentCount === dataFetchCount.current) {
            contentListLoadedOutOfScope(
              data ? convertSketchfabData(data, categories.current) : data
            );
          }
        });
        break;
      case Source.PolyPizza:
        if (categoriesSource.current !== Source.PolyPizza) {
          categories.current = polyPizzaCategories;
          categoriesSource.current = Source.PolyPizza;
          setRender(x => x + 1);
        }

        request = middlewareURL;
        const url = `https://api.poly.pizza/v1/search/${serverSearchText}?Page=${
          contentListPage.current
        }&Limit=${polyPizzaItemLimit}&Category=${
          selectedCategories.current[0]
            ? categories.current[selectedCategories.current[0]]
            : -1
        }`;

        loadData(`${request}?url=${btoa(url)}&source=polypizza`, data => {
          if (currentCount === dataFetchCount.current) {
            contentListLoadedOutOfScope(
              data ? convertPolyPizzaData(data.results) : data
            );
          }
        });
        break;
      case Source.GoogleFonts:
        if (categoriesSource.current !== Source.GoogleFonts) {
          categoriesSource.current = Source.GoogleFonts;
          request = `https://www.googleapis.com/webfonts/v1/webfonts?key=${gfk}&sort=popularity`;
          loadData(request, data => {
            categories.current = ConvertGooglefontsCategories(data);
            contentListLoadedOutOfScope(
              data ? convertGoogleFontsData(data) : data
            );
          });
        }
        break;
    }
  };

  const onItemDetails = (key: string) => {
    if (itemDetails !== key) {
      setItemDetails(key);
    }
  };

  const onVertexKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === 'Enter' && vertexFieldRef.current) {
      evt.stopPropagation();
      vertexFieldRef.current.blur();
    }
  };

  const onTemplateKeyDown = (evt: React.KeyboardEvent) => {
    if (templateFieldRef.current) {
      evt.stopPropagation();
      setTemplateText(templateFieldRef.current.value);
    }
  };

  const onVertexBlur = () => {
    if (vertexFieldRef.current) {
      const vertexValue = parseFloat(vertexFieldRef.current.value);
      if (!isNaN(vertexValue)) {
        setVertexCount(vertexValue);
      }
    }
  };

  const onTagSearch = (text: string) => {
    selectedCategories.current = [];
    noSearchTimeout.current = true;
    updateSearchText.current = true;
    setSearchText(text);
    if (itemDetails) {
      setItemDetails(null);
    }
  };

  const onCategory = (categories: {[id: string]: true}, override?: boolean) => {
    selectedCategories.current = Object.keys(categories ?? {});

    setRender(x => x + 1);

    if (override) {
      updateSearchText.current = true;
      setSearchText('');
      setServerSearchText('');
    }
    if (itemDetails) {
      setItemDetails(null);
    }
  };

  const onModelAdd = async (modelUrl: string, modelData: any) => {
    setImportingAsset(true);
    setImportPopup(true);

    if (modelData) {
      try {
        if (modelData.gltf) {
          const data = await loadModelData(modelUrl, modelData);

          const resourceData = await getResourcesOfGltf(data);
          const byteArrayData = await resourcesToByteArray(data, resourceData);
          const file = await createGlb(data, byteArrayData);
          sendGlb(file);
          setImportingAsset(false);
        } else if (modelData.hdri) {
          const data = await loadFile(modelUrl);
          sendHdri(data);
          setImportingAsset(false);
        } else {
          console.error('Problem with file type', modelUrl, modelData);
          setImportingAsset(false);
        }
      } catch (e) {
        console.error('Problem with conversion', modelUrl, modelData);
        setImportingAsset(false);
      }
    } else {
      // send glb file directly
      const response = await fetch(modelUrl);
      const buffer = await response.arrayBuffer();
      sendGlb(buffer);
      setImportingAsset(false);
    }
  };

  const sendGlb = (glb: ArrayBuffer) => {
    post(glb, `${contentList[itemDetails].name ?? 'default'}.glb`);
  };

  const sendHdri = (hdr: ArrayBuffer) => {
    post(hdr, `${contentList[itemDetails].name ?? 'default'}.hdr`);
  };

  const onFontAdd = async (fontUrl: string, fileName: string) => {
    setImportingAsset(true);
    setImportPopup(true);

    const request = middlewareURL;
    try {
      loadData(
        `${request}?url=${btoa(fontUrl)}&source=googlefonts&type=text`,
        async data => {
          const firstIdx = data.indexOf('url(');
          const secondIdx = data.indexOf(')');
          if (firstIdx && secondIdx) {
            const url = data.substring(firstIdx + 4, secondIdx);
            const file = await loadFile(url);
            sendFont(file, fileName);
            setImportingAsset(false);
          }
        },
        true
      );
    } catch (e) {
      setImportingAsset(false);
    }
  };

  const sendFont = (font: ArrayBuffer, fileName: string) => {
    post(font, `${fileName}.woff`);
  };

  const onWheel = (_evt: React.WheelEvent, allowScroll: boolean) => {
    if (allowScroll && !isRealScroll.current) {
      isRealScroll.current = true;
      setRender(x => x + 1);
    }
    if (!allowScroll && isRealScroll.current) {
      isRealScroll.current = false;
      setRender(x => x + 1);
    }
  };

  const onDropdown = (dropdownId: string, id: string) => {
    setSelectedDropdowns(selected => ({...selected, [dropdownId]: id}));
    switch (dropdownId) {
      case Dropdown.Source:
        setSource(id as Source);
        break;
    }
  };

  // NOTE: out of scope!
  const onAvatarModel = async avatar => {
    const baseId = 'Avatar Model';
    switch (sourceRef.current) {
      case Source.Avaturn:
        setContentList({
          [baseId]: {
            id: baseId,
            name: baseId,
            file: avatar,
            license: 'CC0',
            authors: ['You'],
          },
        });
        setItemDetails(baseId);
        break;
      case Source.ReadyPlayer:
        const link = `${readyPlayerModelApi}${avatar}`;
        setContentList({
          [avatar]: {
            id: avatar,
            name: avatar,
            file: `${link}.glb`,
            thumb: `${link}.png`,
            cover: `${link}.png?camera=fullbody&size=1024`,
            license: 'CC0',
            authors: ['You'],
          },
        });
        setItemDetails(avatar);
        break;
    }
  };

  const getSearchSettings = (s: Source) => {
    switch (s) {
      case Source.Sketchfab:
        return (
          <SearchSettingsContainer>
            <VertexCountTitle>Max vertex count:</VertexCountTitle>
            <ShortTextInput
              width="10%"
              defaultValue={vertexCount}
              ref={vertexFieldRef}
              onKeyDown={onVertexKeyDown}
              onBlur={onVertexBlur}
            />
            <LoginButton variant="secondary">
              Login to Sketchfab account
            </LoginButton>
          </SearchSettingsContainer>
        );
      case Source.GoogleFonts:
        return (
          <SearchSettingsContainer>
            <ShortTextInput
              width="75%"
              defaultValue={templateText}
              ref={templateFieldRef}
              onKeyUp={onTemplateKeyDown}
            />
          </SearchSettingsContainer>
        );
      default:
        return null;
    }
  };

  const showMainLoader =
    !contentListWithFilter || (contentListLoading && !contentListPage.current);

  const dropdowns = Object.values(Dropdown).reduce(
    (acc: {[id: string]: OptionList}, key) => ({
      ...acc,
      [key]: sourceByType[sourceType]
        .map((source: Source) => ({
          id: source,
          name: Source[source],
        }))
        .filter(curr => !WIPFilter.includes(curr.id)),
    }),
    {}
  );

  const selectedCategoriesObject = selectedCategories.current.reduce(
    (acc, key) => {
      return {...acc, [key]: true};
    },
    {}
  ) as {[id: string]: true};

  return (
    <ThemedApp>
      <MainContainer>
        <AssetFilterUI
          themeName={themeName}
          tabs={tabs.map((tab: string) => ({
            id: tab,
            name: tab,
          }))}
          dropdowns={dropdowns}
          categories={Object.keys(categories.current).reduce(
            (acc, key) => [...acc, {id: key, name: categories.current[key]}],
            []
          )}
          onWheel={onWheel}
          selectedTab={sourceType}
          onTab={t => setSourceType(t as SourceType)}
          selectedCategories={selectedCategoriesObject}
          onCategory={onCategory}
          selectedDropdowns={selectedDropdowns}
          onDropdown={onDropdown}
          searchText={searchText}
          onSearchText={text => setSearchText(text)}
          searchSettings={getSearchSettings(source)}
          reset={resetUI}
          resetSearch={resetSearch}
          noSearch={sourceType === SourceType.Avatars}
        >
          {sourceType === SourceType.Avatars ? (
            source === Source.AvatarIntro ? (
              <AvatarIntro
                themeName={themeName}
                onSelect={key => {
                  onDropdown(Dropdown.Source, key);
                }}
              />
            ) : (
              <AvatarGenerator
                source={source}
                onAvatarModel={onAvatarModel}
                reset={resetAvatarGenerator}
                show={!itemDetails}
              />
            )
          ) : (
            <AssetList
              contentList={Object.values(contentListWithFilter)}
              source={source}
              resetHover={resetHover}
              showMainLoader={showMainLoader}
              showLoadMore={showLoadMore}
              isContentListLoading={contentListLoading}
              loadNextPage={loadNextPage}
              onClick={onItemDetails}
              templateText={templateText}
            />
          )}
        </AssetFilterUI>
      </MainContainer>
      {!!itemDetails && !!contentList[itemDetails] && (
        <AssetDetailsPopup
          key={itemDetails}
          id={itemDetails}
          source={source}
          disabled={importingAsset}
          onClose={() => setItemDetails(null)}
          details={contentList[itemDetails]}
          isCover={
            sourceType === SourceType.Textures ||
            source === Source.PolyPizza ||
            source === Source.Sketchfab
          }
          onCategoryAdd={onCategory}
          onTagSearch={onTagSearch}
          onModelAdd={onModelAdd}
          onFontAdd={onFontAdd}
          templateText={templateText}
          themeName={themeName}
        />
      )}
      {importPopup && (
        <ImportProcessPopup
          loading={importingAsset}
          onClose={!importingAsset ? () => setImportPopup(false) : null}
        />
      )}
    </ThemedApp>
  );
};

export default AssetManager;
