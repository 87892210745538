import {GlobalStyles} from '@zappar/foundry-react-components/lib/config/global.styles';
import {themes} from '@zappar/foundry-react-components/lib/theme';
import React from 'react';
import ReactDOM from 'react-dom/client';
// provider
import {ThemeProvider} from 'styled-components';
import AssetManager from './assetmanager';
import {ThemeDesigner} from './designer-theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// eslint-disable-next-line node/no-unsupported-features/node-builtins
const urlParams = new URLSearchParams(window.location.search);

export function isMattercraft() {
  const platform = urlParams.get('platform')?.toLowerCase() === 'mattercraft';
  return platform;
}

function getTheme() {
  const [dark, light] = themes;
  // https://imagetrainer-staging.zap.works/?theme=studio-light
  // or
  // https://imagetrainer-staging.zap.works/?theme=studio-dark
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const urlParams = new URLSearchParams(window.location.search);
  const theme = urlParams.get('theme');
  if (theme === 'studio-dark') {
    return dark;
  } else if (theme === 'studio-light') {
    return light;
  } else if (theme === 'designer') {
    return ThemeDesigner;
  } else {
    // standalone/designer?
    return dark;
  }
}

root.render(
  <React.StrictMode>
    <ThemeProvider theme={getTheme()}>
      <GlobalStyles />
      <AssetManager
        themeName={urlParams.get('theme') ?? 'studio-dark'}
        type={urlParams.get('type')}
        source={urlParams.get('source')}
        allowed={
          urlParams.get('allowed')
            ? urlParams.get('allowed').split(',')
            : undefined
        }
      />
    </ThemeProvider>
  </React.StrictMode>
);
