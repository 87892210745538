import polyhavenLogo from './assets/polyhaven_logo.svg';
import polyhavenLogoLight from './assets/polyhaven_logo_light.svg';
import polyhavenIcon from './assets/polyhaven_icon.svg';
import polypizzaLogo from './assets/polypizza_logo.png';
import polypizzaLogoLight from './assets/polypizza_logo_light.png';
import polypizzaIcon from './assets/polypizza_icon.svg';
import sketchfabLogo from './assets/sketchfab_logo.svg';
import sketchfabLogoLight from './assets/sketchfab_logo_light.svg';
import sketchfabIcon from './assets/sketchfab_icon.svg';
import googlefontsLogo from './assets/googlefonts_logo.svg';
import googlefontsLogoLight from './assets/googlefonts_logo_light.svg';
import googlefontsIcon from './assets/googlefonts_icon.svg';
import readyplayerLogo from './assets/readyplayer_logo.svg';
import readyplayerLogoLight from './assets/readyplayer_logo_light.svg';
import readyplayerIcon from './assets/readyplayer_icon.svg';

import {CategoriesType, Data, Source, SourceType} from './types';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import styled from 'styled-components';

// NOTE: this one is for development purposes!
export const WIPFilter = [SourceType.Fonts, Source.PolyPizza, Source.Sketchfab];

export const boxShadow = 'rgba(0, 0, 0, 0.2) 5px 5px 30px -5px';

export const buttonSize = 36;
export const buttonSizeSml = 28;
export const middlewareURL = 'http://localhost:2222/';
export const polyPizzaItemLimit = 32;
export const sketchfabItemLimit = 24;
export const googleFontsLimit = 32;

export const gfk = 'AIzaSyB0uGVdeQ2B0ngTUqrxeOrMaZnjDCjvEwY';

export const readyPlayerModelApi = 'https://models.readyplayer.me/';
export const readyPlayerOrigin =
  'https://zapworks-asset-importer.readyplayer.me';
export const avaturnOrigin = 'https://zapworks-assetimporter.avaturn.dev';

export const sourceAssets = {
  [Source.PolyHaven]: {
    icon: polyhavenIcon,
    logo: polyhavenLogo,
    logoLight: polyhavenLogoLight,
    url: 'https://polyhaven.com/',
  },
  [Source.Sketchfab]: {
    icon: sketchfabIcon,
    logo: sketchfabLogo,
    logoLight: sketchfabLogoLight,
    url: 'https://sketchfab.com/',
  },
  [Source.PolyPizza]: {
    icon: polypizzaIcon,
    logo: polypizzaLogo,
    logoLight: polypizzaLogoLight,
    url: 'https://poly.pizza/',
  },
  [Source.GoogleFonts]: {
    icon: googlefontsIcon,
    logo: googlefontsLogo,
    logoLight: googlefontsLogoLight,
    url: 'https://fonts.google.com/',
  },
  [Source.ReadyPlayer]: {
    icon: readyplayerIcon,
    logo: readyplayerLogo,
    logoLight: readyplayerLogoLight,
    url: 'https://readyplayer.me/',
  },
  [Source.Avaturn]: {
    icon: googlefontsIcon,
    logo: googlefontsLogo,
    logoLight: googlefontsLogoLight,
    url: 'https://avaturn.me/',
  },
};

export const sourceByType = {
  [SourceType.Models]: [Source.PolyHaven, Source.Sketchfab, Source.PolyPizza],
  [SourceType.Textures]: [Source.PolyHaven],
  [SourceType.Hdris]: [Source.PolyHaven],
  [SourceType.Fonts]: [Source.GoogleFonts],
  [SourceType.Avatars]: [
    Source.AvatarIntro,
    Source.ReadyPlayer,
    Source.Avaturn,
  ],
};

export const menuTitleByType = {
  [SourceType.Models]: '3D Models',
  [SourceType.Textures]: 'Textures',
  [SourceType.Hdris]: 'HDRIs',
  [SourceType.Fonts]: 'Fonts',
  [SourceType.Avatars]: 'Avatars',
};

// urlParamMatch contains ALL parameter values, both types and sources
export const urlParamMatch = {
  ['models']: SourceType.Models,
  ['fonts']: SourceType.Fonts,
  ['textures']: SourceType.Textures,
  ['hdris']: SourceType.Hdris,
  ['avatars']: SourceType.Avatars,
  ['polyhaven']: Source.PolyHaven,
  ['sketchfab']: Source.Sketchfab,
  ['polypizza']: Source.PolyPizza,
  ['googlefonts']: Source.GoogleFonts,
  ['readyplayer']: Source.ReadyPlayer,
  ['avaturn']: Source.Avaturn,
  ['intro']: Source.AvatarIntro,
};

export const polyPizzaCategories = {
  ['Food & Drink']: 0,
  Clutter: 1,
  Weapons: 2,
  Transport: 3,
  ['Furniture & Decor']: 4,
  Objects: 5,
  Nature: 6,
  Animals: 7,
  Buildings: 8,
  ['People & Characters']: 9,
  ['Scenes & Levels']: 10,
  Other: 11,
};

export const convertPolyHavenData = (data): Data | undefined =>
  Object.keys(data).reduce((acc, key) => {
    const {name, categories, tags, authors} = data[key];
    return {
      ...acc,
      [key]: {
        id: key,
        name,
        categories,
        tags,
        license: 'CC0',
        thumb: `https://cdn.polyhaven.com/asset_img/thumbs/${key}.png?width=450&height=300`,
        cover: `https://cdn.polyhaven.com/asset_img/primary/${key}.png?height=780`,
        authors: authors ? Object.keys(authors) : undefined,
      },
    };
  }, {});

export const convertPolyPizzaData = (data): Data | undefined => {
  try {
    return data.reduce((acc, item) => {
      const {
        ID,
        Title,
        Tags,
        Category,
        Description,
        Download,
        Thumbnail,
        Licence,
        Creator,
      } = item;
      return {
        ...acc,
        [ID]: {
          id: ID,
          name: Title,
          categories: [Category],
          tags: Tags,
          file: `https://corsproxy.io/?${Download}`,
          thumb: Thumbnail,
          cover: Thumbnail,
          description: Description,
          license: Licence,
          authors: Creator?.Username ? [Creator.Username] : undefined,
        },
      };
    }, {});
  } catch (e) {
    return undefined;
  }
};

export const convertSketchfabData = (data, allCategories): Data | undefined => {
  try {
    return data.results.reduce((acc, item) => {
      const {
        uid,
        name,
        categories,
        tags,
        thumbnails,
        description,
        license,
        user,
      } = item;
      const thumb = thumbnails.images.reduce(
        (acc, thumb) =>
          thumb.width > 199 && thumb.width < 300 ? thumb.url : acc,
        undefined
      );

      const cover = thumbnails.images.reduce(
        (acc, thumb) =>
          thumb.width > 1000 && thumb.width < 1300 ? thumb.url : acc,
        undefined
      );

      return {
        ...acc,
        [uid]: {
          id: uid,
          name,
          categories: categories.map(cat =>
            Object.keys(allCategories).reduce(
              (acc: string, key: string) =>
                allCategories[key] === cat.name ? key : acc,
              cat.name
            )
          ),
          tags: tags.map(tag => tag.slug),
          thumb,
          cover,
          description,
          license: license?.label,
          authors: [user.displayName],
        },
      };
    }, {});
  } catch (e) {
    return undefined;
  }
};

export const convertGoogleFontsData = (data): Data | undefined =>
  data.items.reduce((acc, item) => {
    const {family, category, files, subsets, variants} = item;
    return {
      ...acc,
      [family]: {
        id: family,
        name: family,
        categories: [category],
        variants,
        subsets,
        license: 'SIL Open Font License',
        file: files[400] ?? files['regular'] ?? files[Object.keys(files)[0]],
      },
    };
  }, {});

export const filterSearch = (str: string, content: Data) => {
  if (!str || !content) return content;

  return Object.keys(content).reduce((acc, id) => {
    const doesContain = Object.keys(content[id]).reduce((included, key) => {
      const item = content[id][key];
      if (
        typeof item === 'string' &&
        item.toLowerCase().includes(str.toLowerCase())
      ) {
        return true;
      }
      if (key === 'tags') {
        return item.reduce(
          (tagIncluded, tag) =>
            tag.toLowerCase().includes(str.toLowerCase()) ? true : tagIncluded,
          false
        );
      }
      return included;
    }, false);

    if (doesContain) {
      return {...acc, [id]: content[id]};
    }
    return acc;
  }, {});
};

export const filterContent = (selected: string[], content: any) => {
  if (!selected.length || !content) return content;
  return Object.keys(content).reduce((acc, key) => {
    const doesContain = Object.keys(content[key]).reduce((included, item) => {
      if (
        item === 'categories' &&
        selected.filter(id => {
          return !content[key][item].includes(id);
        }).length
      ) {
        return false;
      }
      return included;
    }, true);

    if (doesContain) {
      return {...acc, [key]: content[key]};
    }
    return acc;
  }, {});
};

export const loadData = (
  request: string,
  callback: (data: any) => void,
  isText?: boolean
) => {
  try {
    fetch(request)
      .then(response => {
        return isText ? response.text() : response.json();
      })
      .then(data => {
        callback(data);
      });
  } catch (e) {
    callback(null);
  }
};
const findFileInData = (fileName, modelData): string | undefined => {
  if (typeof modelData === 'object') {
    const itemArray = Array.isArray(modelData)
      ? modelData
      : Object.values(modelData);

    return itemArray.reduce((acc, data) => {
      const d = findFileInData(fileName, data);
      if (d) {
        return d;
      }
      return acc;
    }, undefined);
  } else {
    if (typeof modelData === 'string' && modelData.includes(fileName)) {
      return modelData;
    }
    return undefined;
  }
};

export const loadModelData = async (url: string, modelData: any) => {
  const response = await fetch(url);
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const textDecoder = new TextDecoder();
  const jsonString = textDecoder.decode(await response.arrayBuffer());
  const gltfObject = JSON.parse(jsonString);
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  if (modelData) {
    // undefined modelData means we have a glb file
    if (gltfObject.images) {
      for (let i = 0; i < gltfObject.images.length; i++) {
        let fileName = gltfObject.images[i].uri;
        if (fileName.startsWith('textures/')) {
          fileName = fileName.replace('textures/', '');
        }
        const path = findFileInData(fileName, modelData);
        if (path) {
          gltfObject.images[i].uri = path;
        } else {
          // TODO: troubleshoot missing images
          gltfObject.images[i].uri = path;
        }
      }
    }

    if (gltfObject.buffers) {
      for (let i = 0; i < gltfObject.buffers.length; i++) {
        if (gltfObject.buffers[i].uri) {
          const path = findFileInData(gltfObject.buffers[i].uri, modelData);
          gltfObject.buffers[i].uri = path;
        }
      }
    }
  }
  return gltfObject;
};

export const loadModelScene = async (url: string, modelData: any) => {
  const gltfLoader = new GLTFLoader();
  if (!modelData) {
    const glb = await gltfLoader.loadAsync(url);
    return glb.scene;
  } else {
    const gltfObject = await loadModelData(url, modelData);

    const gltf = await gltfLoader.parseAsync(JSON.stringify(gltfObject), '');
    return gltf.scene;
  }
};

export const getHeightValueFromWidthField = (
  inputWidth: string,
  aspectRatio: number
) => {
  // inputWidth will be of form '10mm' and we need to return the height including the units suffix
  const widthValue = Number(inputWidth.substring(0, inputWidth.length - 2));
  const widthUnits = inputWidth.substring(inputWidth.length - 2);
  const heightValue = widthValue / aspectRatio;
  return `${heightValue}${widthUnits}`;
};

export const post = (ab: ArrayBuffer, name) => {
  const opener = window.opener || window.parent;
  opener.postMessage(
    {
      t: 'znewfile',
      source: 'framed-asset-importer',
      undefined,
      files: [
        {
          filename: name,
          data: ab,
        },
      ],
    },
    '*'
  );
};

const hex = (buffer, limit) => {
  let digest = '';
  const view = new DataView(buffer);
  for (let i = 0; i < view.byteLength; i += 4) {
    const value = view.getUint32(i);
    const stringValue = value.toString(16);
    const padding = '00000000';
    const paddedValue = (padding + stringValue).slice(-padding.length);
    digest += paddedValue;
  }

  return digest.slice(0, limit);
};

export const getHashString = async (str: string, limit = 8) => {
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const buffer = new TextEncoder().encode(str);
  return hex(await crypto.subtle.digest('SHA-256', buffer), limit);
};

export const ConvertPolyhavenCategories = (data: any): CategoriesType =>
  Object.keys(data ?? {}).reduce(
    (acc: {[id: string]: string}, key: string) => ({
      ...acc,
      [key]: key,
    }),
    {}
  );

export const ConvertSketchfabCategories = (data: any): CategoriesType =>
  (data?.results ?? []).reduce(
    (acc: {[id: string]: string}, curr) => ({
      ...acc,
      [curr.name]: curr.slug,
    }),
    {}
  );

export const ConvertGooglefontsCategories = (data: any): CategoriesType =>
  (data.items ?? []).reduce(
    (acc: {[id: string]: string}, item) =>
      acc[item.category] ? acc : {...acc, [item.category]: item.category},
    {}
  );

export const ShortTextInput = styled.input<{width: string; darken?: boolean}>`
  width: ${props => props.width};
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  padding: 0 16px;
  background-color: ${props =>
    props.darken
      ? props.theme.colors.hoverBackground
      : props.theme.colors.menuBackgroundColor};
  color: ${props => props.theme.colors.fontColor};
  border: none;
  outline: none;
  :active {
    outline: none;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const SettingContainer = styled.div`
  margin-bottom: 40px;
`;

export const getReadyPlayerLink = (
  baseLink: string,
  avatarSettings: {[key: string]: string}
) =>
  `${baseLink}?${Object.keys(avatarSettings).reduce(
    (acc: string, key: string, idx: number) =>
      `${acc}${idx ? '&' : ''}${key}=${avatarSettings[key]}`,
    ''
  )}`;

export const allowedTabs = (allowed?: string[]) => {
  const allSourceType = Object.keys(SourceType)
    .map((key: string) => SourceType[key])
    .filter(curr => !WIPFilter.includes(curr));
  if (allowed) {
    return allowed.reduce((acc, sourceType) => {
      if (allSourceType.includes(sourceType) && !acc.includes(sourceType)) {
        return [...acc, sourceType];
      }
      return acc;
    }, []);
  } else {
    return allSourceType;
  }
};
